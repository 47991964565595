/* Global imports */
import React from 'react'
import { array, number } from 'prop-types'
import styled from 'styled-components'
import { Carousel, CarouselItem } from 'reactstrap'

/* Local imports */
import Slide from './Slide'

/* Component definition */
const Slides = ({ slide, slides }) => {
  return (
    <SCarousel
      activeIndex={slide}
      next={() => null}
      previous={() => null}>

      { getSlideComponents(slides) }

    </SCarousel>
  )
}

/* PropTypes */
Slides.propTypes = {
  slide:  number.isRequired,
  slides: array.isRequired,
}
Slides.defaultProps = {
  slides: [],
}

/* Local utility functions */
// getSlides :: {Slide} -> SCarouselItem
const getSlideComponents =
      slides => slides.map(
        ({ _id, backgroundUrl, description, subTitle, title }) => (
          <CarouselItem key={_id}>
            <Slide
              background={backgroundUrl}
              subtitle={subTitle}
              titleText={title}
              description={description}
            />
          </CarouselItem>
        )
      )

/* Styles */
const SCarousel = styled(Carousel)`
  height:           100%;
  flex: 1;

  .carousel-inner {
    height: 100%;

    .carousel-item {
      height: 100%;
    }
  }
`

export default Slides
