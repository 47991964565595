/* Global imports */
import React from 'react'
import { View } from 'react-native'
import { arrayOf } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import Text from 'Components/Text'
import Image from 'Components/Image'
import { productDetail as colors } from 'Definitions/colors'
import { gaps } from 'Definitions/measures'
import { award } from 'Definitions/types'

/* Awards definition */
const Awards = ({ awards }) => {
  return (
    <Wrapper>
      <Title
        color={colors.text}
        type="display3"
        value="productDetail.awards"
        translate
      />
      {
        awards.map((award) => (
          <Award key={award._id}>
            <SImage
              mHeight={70}
              mWidth={70}
              resizeMode="contain"
              source={award.logoUrl}
              alt={award.name}
            />
          </Award>
        ))
      }
    </Wrapper>
  )
}

/* PropTypes */
Awards.propTypes = {
  awards: arrayOf(award).isRequired,
}

Awards.defaultProps = {
  awards: [],
}

/* Local utility functions */

/* Local Styled Awardss */
const Wrapper = styled(View)`
  border-bottom-color: ${colors.awardsRuler};
  border-bottom-width: 1px;
  display:             flex;
  flex-direction:      row;
  justify-content:     flex-end;
  padding-bottom:      ${gaps.s};
  position:            relative;
`

const Title = styled(Text)`
  bottom:   0;
  left:     0;
  position: absolute;
`

const Award = styled(View)`
  padding: 0 15px;
`

const SImage = styled(Image)`
  height: 100px;
`

export default Awards
