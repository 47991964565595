/* Global imports */
import React from 'react'
import { TextInput, View } from 'react-native'
import { array, func, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import Text from '../Text'
import { loginScreen } from '../../definitions/colors'

/* Component definition */
const InputForm = ({ id, onChange, onEnter, title, type }) => {
  return (
    <View>
      <SText value={title} type="caption" />
      <Input
        onKeyPress={callOnEnter(onEnter)}
        onChangeText={text => onChange(text, id)}
        secureTextEntry={type === 'password'}
      />
    </View>
  )
}

/* PropTypes */
InputForm.propTypes = {
  id:       string.isRequired,
  onChange: func.isRequired,
  onEnter:  func,
  title:    oneOfType([string, array]).isRequired,
  type:     string,
}

InputForm.defaultProps = {
  onEnter: () => void 0,
}

/* Local utility functions */

const callOnEnter = callback => event => {
  if (event.nativeEvent.key === 'Enter') {
    callback()
  }
}

/* Styles */
const SText = styled(Text)`
  padding: 10px 0;
`

const Input = styled(TextInput)`
  width: 272px;
  border-radius: 6px;
  background-color: ${loginScreen.input};
  padding: 10px 10px;
`

export default InputForm
