/* Global imports */
import React from 'react'
import { Platform, TouchableOpacity } from 'react-native'
import { element, object, number, string } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import Image from 'Components/Image'
import { productShape } from 'Definitions/types'

/* Component definition */
const Bottle = ({
  className,
  history,
  mHeight,
  mWidth,
  product,
  resizeMode,
  navigation,
  Footer,
}) => {
  return (
    <Wrapper className={className} onPress={() => _goToDetail(history, navigation, product)}>
      <SImage
        alt={product.name}
        source={product.imageUrl}
        mHeight={mHeight}
        mWidth={mWidth}
        imageHeight={mHeight}
        resizeMode={resizeMode}
      />
      { Footer }
    </Wrapper>
  )
}

/* PropTypes */
Bottle.propTypes = {
  Footer:      element,
  className:   string,
  history:     object,
  imageHeight: string,
  mHeight:     number,
  mWidth:      number,
  navigation:  object,
  product:     productShape,
  resizeMode:  string,
}

Bottle.defaultProps = {}

/* Local utility functions */

const _goToDetail = (history, navigation, product) => {
  if (Platform.OS === 'web') {
    history.push({ pathname: `/product/${product._id}`, product })
  } else if (navigation) {
    navigation.push('ProductDetail', { product: product })
  }
}

/* Styles */
const Wrapper = styled(TouchableOpacity)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SImage = styled(Image)`
  max-width: 100vw;
  height: ${p => p.imageHeight ? p.imageHeight : null}px;
  ${p => Platform.OS === 'web' && p.resizeMode ? `object-fit: ${p.resizeMode}` : null};
`

export default Bottle
