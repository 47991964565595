/* Global imports */
import React from 'react'
import { Dimensions, Platform, View } from 'react-native'
import { bool, string } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import Text from 'Components/Text'
import { catalogContent } from 'Definitions/colors'

const { width } = Dimensions.get('window')

/* Component definition */
const BottleFooter = ({ simpleFooter, title }) => (
  <Wrapper simpleFooter={simpleFooter}>
    <SText type="headline" value={title} numberOfLines={1} />
  </Wrapper>
)

/* PropTypes */
BottleFooter.propTypes = {
  simpleFooter: bool,
  title:        string.isRequired,
}

BottleFooter.defaultProps = {}

/* Local utility functions */

/* Styles */

const Wrapper = styled(View)`
  border-right-color: ${p => p.simpleFooter ? 'transparent' : catalogContent.border};
  border-right-width: ${p => p.simpleFooter ? 0 : '1px'};
  height: 60px;
  text-align: center;
  width: ${Platform.OS !== 'web' ? width : '100%'};
  padding: 0 10px;
`

const SText = styled(Text)`
  text-align: center;
`

export default BottleFooter
