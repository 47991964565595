import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Platform, View } from 'react-native'

/* FIXME: Remove !important
  due to stylesheet imports order
*/

const GlobalStyle = Platform.OS === 'web' ? createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato|Arial|Playfair+Display');

  html {
    font-family: 'Lato', sans-serif !important;
    font-size:   16px !important;
    height: 100%;
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;

    body {
      height: 100%;

      #root {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }

  a {
    outline: none !important;
  }

  a:hover {
    text-decoration: none !important;
  }

  ::-webkit-scrollbar {
      display: none !important;
  }
` : <View />

export default GlobalStyle
