/* Global imports */
import React from 'react'
import { Dimensions, Text, TouchableOpacity } from 'react-native'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import { catalogBottomBar } from 'Definitions/colors'

const { width } = Dimensions.get('window')
/* Component definition */
const CategorySelectorItem = ({ active, name, onClick, visible }) => {
  if (visible) {
    return (
      <Wrapper active={active} onPress={onClick}>
        <SText active={active}>{ name }</SText>
      </Wrapper>
    )
  }

  return null
}

/* PropTypes */
CategorySelectorItem.propTypes = {
  active:  bool,
  id:      string.isRequired,
  name:    string.isRequired,
  onClick: func.isRequired,
  visible: bool.isRequired,
}

CategorySelectorItem.defaultProps = {
  subCategories: [],
  visible:       false,
}

/* Local utility functions */

/* Styles */
const Wrapper = styled(TouchableOpacity)`
    justify-content: center;
    background-color: ${catalogBottomBar.background};
    border-top-width: 4px;
    border-color: ${p => p.active ? catalogBottomBar.activeItem : catalogBottomBar.border};
    width: ${width / 5}px;
`

const SText = styled(Text)`
  padding: 0 5px;
  color: ${catalogBottomBar.text};
  text-align: center;
`
export default CategorySelectorItem
