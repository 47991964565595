/* Global imports */
import React from 'react'
import { View } from 'react-native'
import { array, string } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import { gaps } from 'Definitions/measures'
import { productDetail } from 'Definitions/colors'
import Text from 'Components/Text'

const renderRow = (cells, index) => {
  return (
    <TableRow key={index}>
      {
        cells.map(
          (cell, i) => (
            <TableCell key={i} width={cells.length}>
              <Text value={cell} type="body" translate={i === 0} />
            </TableCell>
          ))
      }
    </TableRow>
  )
}

/* Component definition */
const ProductTable = ({ title, data: rows }) => {
  return (
    <Wrapper>
      <SText
        translate
        type="headline"
        value={title}
      />
      <Table>
        {
          rows.map((row, index) => {
            if (row[1]) {
              return renderRow(row, index)
            }

            return null
          })
        }
      </Table>
    </Wrapper>
  )
}

/* PropTypes */
ProductTable.propTypes = {
  data:  array.isRequired,
  title: string.isRequired,
}

ProductTable.defaultProps = {}

/* Local utility functions */

/* Styles */

const Wrapper = styled(View)`
  border-top-color: ${productDetail.awardsRuler};
  border-top-width: 3px;
  padding-bottom:   ${gaps.m};
`

const SText = styled(Text)`
  padding:    ${gaps.s};
  text-align: center;
`

// table-layout: fixed;
const Table = styled(View)`
  justify-content: center;
  text-align:      center;
  width:           100%;
`

const TableRow = styled(View)`
  flex-direction: row;
  flex-wrap:      wrap;
`

const TableCell = styled(View)`
  border:     1px solid ${productDetail.border};
  padding:    10px ${gaps.m};
  text-align: ${props => props.position === 'left' ? 'left' : 'center'};
  width:      ${props => 100 / props.width + '%'};
`

export default ProductTable
