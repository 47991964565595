import React, { useEffect } from 'react'
import { bool, func, number, object, string } from 'prop-types'
import { connect } from 'react-redux'
import { Image, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { compose } from 'crocks'

// Local imports
import { icons } from 'Assets/images'
import { headerHeight } from 'Definitions/measures'
import NotificationsIcon from 'NavBar/NotificationsIcon'
import SyncIcon from 'NavBar/SyncIcon'
import {
  getToken,
} from 'Reducers/user'
import {
  getUnreadCount,
  requestUnread,
} from 'Reducers/notifications'
import {
  getSync,
} from 'Reducers/sync'

const TopBarButtons = ({
  getUnreadNotifications,
  history,
  isSync,
  token,
  unReadNotifications,
}) => {
  // Get unread notifications every minute.
  useEffect(() => {
    if (token) {
      const id = setInterval(() => {
        getUnreadNotifications()
      }, 60000)

      return () => {
        clearInterval(id)
      }
    }
  }, [])

  return (
    <Wrapper>
      { token && history.location.pathname !== '/sync' && <SyncIcon
        synchronized={isSync}
        onClick={() => history.push('/sync')}
      /> }
      { renderSearchIcon(history) }
      { token && <NotificationsIcon
        onClick={() => history.push('/notifications')}
        number={unReadNotifications}
      /> }
    </Wrapper>
  )
}

TopBarButtons.propTypes = {
  getUnreadNotifications: func.isRequired,
  history:                object,
  isSync:                 bool,
  token:                  string,
  unReadNotifications:    number,
}

/* Local utility functions */
const renderSearchIcon = (history) => {
  const pathName = history.location.pathname

  if (pathName !== '/catalog/search' && pathName !== '/marketingResources') {
    return (
      <ImageWrapper onPress={() => history.push('/catalog/search')}>
        <SImage alt="search" source={icons.search} />
      </ImageWrapper>
    )
  }

  return null
}

const Wrapper = styled(View)`
  align-content:   center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
`

const ImageWrapper = styled(TouchableOpacity)`
  height: ${headerHeight};
  width: ${headerHeight};

  :hover {
   opacity: 0.6;
  }
`

const SImage = styled(Image)`
  flex: 1;
  height: auto;
`

const mapStateToProps = (state) => {
  return {
    isSync:              getSync(state).sync,
    token:               getToken(state),
    unReadNotifications: getUnreadCount(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUnreadNotifications: () => dispatch(requestUnread()),
})

const enhanceComponent = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)

export default enhanceComponent(TopBarButtons)
