export const mainLogo = require('./_caballero/xhdpi/Logo/Logo-mini.png')
export const fullLogo = require('./_caballero/xhdpi/Logo/Logo-full.png')
export const blueLogo = require('./_caballero/xhdpi/Logo/Logo-blue.png')
export const tabMenu = require('./_caballero/hdpi/tab_menu.png')
export const disclaimerBackground = require('./_caballero/Bg-home.png')
/* This image is temporary, the images should be fetched from the server */

export const awards = [
  require('./_caballero/Awards/awards.png'),
]

export const icons = {
  arrow:      require('./_caballero/hdpi/Icons/Arrow.png'),
  back:       require('./_caballero/hdpi/Icons/Arrow-yellow.png'),
  backMenu:   require('./_caballero/hdpi/Icons/Arrow-white.png'),
  checkboxes: {
    active:   require('./_caballero/hdpi/Icons/Checkbox-filled.png'),
    inactive: require('./_caballero/hdpi/Icons/Checkbox.png'),
  },
  close:          require('./_caballero/hdpi/Icons/Cross.png'),
  download:       require('./_caballero/hdpi/Icons/Icloud-Download.png'),
  menu:           require('./_caballero/hdpi/Icons/Menu.png'),
  notifications:  require('./_caballero/hdpi/Icons/bell.png'),
  resources:      require('./_caballero/hdpi/Icons/Breifcase.png'),
  resourcesWhite: require('./_caballero/hdpi/Icons/Breifcase_white.png'),
  search:         require('./_caballero/hdpi/Icons/Bitmap.png'),
  searchBlue:     require('./_caballero/hdpi/Icons/search.png'),
  send:           require('./_caballero/hdpi/Icons/Airplane.png'),
  sendWhite:      require('./_caballero/hdpi/Icons/Airplane_white.png'),
  sync:           require('./_caballero/hdpi/Icons/sync.png'),
  unSync:         require('./_caballero/hdpi/Icons/un_sync.png'),
}
