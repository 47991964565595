/* Global imports */
import React from 'react'
import { bool, arrayOf, func, object } from 'prop-types'
import { ActivityIndicator, FlatList, Platform, View } from 'react-native'
import styled from 'styled-components'
import { pathOr, prop } from 'ramda'

/* Local imports */
import IconButton from '../IconButton'
import { icons } from 'Assets/images'
import Text from 'Components/Text'
import { marketingResourceShape } from 'Definitions/types'
import { catalogContent, productDetail } from 'Definitions/colors'
import ErrorMessage from 'Components/ErrorMessage'
import Share from 'Services/share'
import Download from 'Services/download'
import I18n from 'Assets/lang'

const { resourcesMenu } = productDetail

/* Component definition */
const ResourceMenu = ({
  error,
  fetching,
  onRefresh,
  resources: marketingResources,
  navigation,
}) => {
  const resources = pathOr(marketingResources, ['state', 'params', 'resources'], navigation)

  if (fetching && resources.length === 0) {
    return (
      <MessageWrapper>
        <ActivityIndicator size="large" color={catalogContent.spinner} />
      </MessageWrapper>
    )
  }

  if (error) {
    return (
      <MessageWrapper>
        <ErrorMessage />
      </MessageWrapper>
    )
  }

  if (resources.length === 0) {
    return (
      <MessageWrapper>
        <Text value="marketingResources.noMarketingResources" translate />
      </MessageWrapper>
    )
  }

  return (
    <FlatList
      data={resources}
      keyExtractor={prop('_id')}
      renderItem={({ item }) => renderResource(item)}
      refreshing={fetching}
      onRefresh={onRefresh}
    />
  )
}

/* PropTypes */
ResourceMenu.propTypes = {
  error:      bool,
  fetching:   bool,
  navigation: object,
  onRefresh:  func.isRequired,
  resources:  arrayOf(marketingResourceShape),
}

ResourceMenu.defaultProps = {
  fetching:  false,
  onRefresh: () => null,
  resources: [],
}

/* Local utility functions */
const renderSendButton = (fileUrl) => {
  if (Platform.OS === 'web') {
    return null
  }

  return (
    <IconButton
      icon={icons.send}
      text="send"
      type="caption"
      onClick={() => Share(`${I18n.t('marketingResources.linkMessage')} ${fileUrl}`)}
    />
  )
}

const renderResource = (resource) => {
  return (
    <SRow key={resource._id}>
      <TitleCol>
        <Title value={resource.name} />
      </TitleCol>
      <ButtonsCol>
        <IconButton
          icon={icons.download}
          text="download"
          type="caption"
          onClick={() => Download(resource.fileUrl)}
        />
        { renderSendButton(resource.fileUrl) }
      </ButtonsCol>
    </SRow>
  )
}

/* Local Styled Components */

const SRow = styled(View)`
  border-bottom-width:  1px;
  border-bottom-color:  ${resourcesMenu.item.borderBottom};
  flex-direction: row;
`

const Title = styled(Text)`
  font-weight: bold;
  padding:     10px 0 0 10px;
`

const TitleCol = styled(View)`
  flex: 1;
  align-self: center;
`

const ButtonsCol = styled(View)`
  align-items:    flex-end;
  flex-direction: row;
`

const MessageWrapper = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
`

export default ResourceMenu
