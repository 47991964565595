/* Global imports */
import React from 'react'
import { arrayOf } from 'prop-types'
import { FlatList, View } from 'react-native'
import styled from 'styled-components'
import { prop } from 'ramda'
import { isMobileOnly } from 'react-device-detect'

/* Local imports */
import { productShape } from 'Definitions/types'
import Bottle from './Bottle'
// import BottleFooter from 'Components/Catalog/BottleFooter'
import Text from 'Components/Text'
import BottleSliderListMobile from 'Components/Catalog/BottleSliderListMobile'
import { catalogContent } from 'Definitions/colors'

const isTouchDevice = 'ontouchstart' in window || (navigator.msMaxTouchPoints > 0)

/* Component definition */
const BottleSliderList = ({ products }) => {
  if (isMobileOnly) {
    return (
      <BottleSliderListMobile products={products} />
    )
  }

  return (
    <Wrapper>
      { renderList(products) }
    </Wrapper>
  )
}

/* PropTypes */
BottleSliderList.propTypes = {
  products: arrayOf(productShape),
}
BottleSliderList.defaultProps = {}

/* Local utility functions */

const renderList = (products) => {
  if (!isTouchDevice) {
    return (
      <FlatList
        data={products}
        keyExtractor={prop('_id')}
        numColumns={3}
        renderItem={({ item }) => renderProduct(item)}
      />
    )
  }

  return (
    <FlatList
      data={products}
      keyExtractor={prop('_id')}
      horizontal={true}
      renderItem={({ item }) => renderProduct(item)}
    />
  )
}

const renderProduct = (product) => {
  return (
    <Product>
      <Bottle
        product={product}
      />
      <FooterWrapper>
        <Footer value={product.name} type="headline" />
      </FooterWrapper>
    </Product>
  )
}

/* Styles */
const Wrapper = styled(View)`
  height: 100%;
  width: 100%;
`

const Product = styled(View)`
  ${!isTouchDevice && `width: 33%`};
  align-self: flex-end;
`

const FooterWrapper = styled(View)`
  ${!isTouchDevice ? null
    : `border-right-color: ${catalogContent.border};
    border-right-width: 1px;`
};
`

const Footer = styled(Text)`
  text-align: center;
  padding: 0 10px;
`

export default BottleSliderList
