/* Global imports */
import React from 'react'
import { View } from 'react-native'
import { element, func } from 'prop-types'
import styled from 'styled-components'
import { withGetScreen } from 'react-getscreen'
import { isAndroid, isMobileOnly } from 'react-device-detect'

/* Local imports */
import TopBar from './TopBar'
import BottomBar from './BottomBar'
import ForceDownloadApp from 'Components/ForceDownloadApp'

const items = [
  { link: '/', text: 'bottomBar.lustau' },
  { link: '/catalog', text: 'bottomBar.spirits' },
  { link: '/product', text: 'bottomBar.liquors' },
  { link: '/other', text: 'bottomBar.sherry' },
  { link: '/one', text: 'bottomBar.wines' },
]

/* Component definition */
const MainFrame = ({ children }) => {
  if (isMobileOnly && isAndroid) {
    return (
      <ForceDownloadApp />
    )
  }

  if (isMobileOnly) {
    return (
      <Wrapper>
        <TopBar />
        <MainContent>
          { children }
        </MainContent>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <TopBar />
      <MainContent>
        { children }
      </MainContent>
      <SBottomBar items={items} />
    </Wrapper>
  )
}

/* PropTypes */
MainFrame.propTypes = {
  children: element,
  isMobile: func.isRequired,
}

MainFrame.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const Wrapper = styled(View)`
  display:        flex;
  flex-direction: column;
  height:         100%;
`

const MainContent = styled(View)`
  flex:   1 1 auto;
  ${isMobileOnly ? `overflow: scroll` : 'overflow: hidden'};
  height: 100px;
  z-index: 0;
`

const SBottomBar = styled(BottomBar)`
  z-index: 0;
`

export default withGetScreen(MainFrame)
