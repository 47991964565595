/* Global imports */
import React, { useEffect } from 'react'
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types'
import {
  ActivityIndicator,
  Platform,
  View,
} from 'react-native'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'

/* Local imports */
import BrandSlider from './BrandSlider'
import BottleSlider from './BottleSlider'
import CategoryFilter from './CategoryFilter'
import ErrorMessage from 'Components/ErrorMessage'
import Text from 'Components/Text'
import { getSubCategories } from 'Services/utils'
import {
  getList as getBrandList,
} from 'Reducers/brands'

import {
  getList as getCategoryList,
} from 'Reducers/categories'

import {
  getList as getProductList,
  getError as getProductError,
  getFetching as getProductsFetching,
  requestList as requestProductList,
} from 'Reducers/products'

import {
  categoryShape,
  productShape,
  brandShape,
} from 'Definitions/types'

import { catalogContent } from 'Definitions/colors'

/* Component definition */
const Catalog = ({
  brands,
  brandSelected,
  categories,
  categorySelected,
  error,
  fetching,
  getProducts,
  products,
  navigation,
}) => {
  const subCategories = getSubCategories(categories, categorySelected)

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const params = brandSelected ? { brand: brandSelected } : { category: categorySelected }

      const listener = navigation.addListener('willFocus', () => {
        getProducts(params)
      })

      return () => { listener.remove() }
    }
  }, [categorySelected])

  useEffect(() => {
    if (brandSelected || categorySelected) {
      const params = brandSelected ? { brand: brandSelected } : { category: categorySelected }
      getProducts(params)
    }
  }, [brandSelected, categorySelected])

  if (fetching && products.length === 0) {
    return (
      <MessageWrapper>
        <ActivityIndicator size="large" color={catalogContent.spinner} />
      </MessageWrapper>
    )
  }

  if (error) {
    return (
      <MessageWrapper>
        <ErrorMessage />
      </MessageWrapper>
    )
  }

  return (
    <Wrapper brands={brands}>
      { Platform.OS === 'web' && !isMobileOnly ? <BrandSlider categorySelected={categorySelected} /> : null }
      <BottlesWrapper>
        { _renderSubCategories(subCategories, getProducts, categorySelected) }
        { _renderBottles(fetching, products) }
      </BottlesWrapper>
    </Wrapper>
  )
}

/* PropTypes */
Catalog.propTypes = {
  brandSelected:    string,
  brands:           arrayOf(brandShape),
  categories:       arrayOf(categoryShape),
  categorySelected: string,
  error:            bool,
  fetching:         bool,
  getProducts:      func.isRequired,
  navigation:       object,
  products:         arrayOf(productShape),
}

Catalog.defaultProps = {}

/* Local utility functions */
const _renderBottles = (fetching, products) => {
  if (products.length === 0) {
    return (
      <MessageWrapper>
        <Text value="catalog.noProducts" translate />
      </MessageWrapper>
    )
  }

  return (
    <BottleSlider products={products} fetching={fetching} />
  )
}

const _renderSubCategories = (subCategories, getProducts, categorySelected) => {
  if (Platform.OS !== 'web' || subCategories.length < 1 || isMobileOnly) {
    return null
  }

  return (
    <CategoryFilter
      items={subCategories}
      parent={categorySelected}
      onSelect={selectedCategory => getProducts({ category: selectedCategory })}
    />
  )
}
/* Local Styled Components */

// TODO: Fix styles to not need brands.
const Wrapper = styled(View)`
  flex: 1;
  padding-bottom: ${p => Platform.OS === 'web' && !isMobileOnly ? '0px' : p.brands.length < 1 ? '50px' : '100px'};
`

const MessageWrapper = styled(View)`
  align-items:     center;
  align-self:      center;
  flex:            1;
  justify-content: center;
`

const BottlesWrapper = styled(View)`
  align-items:    flex-end;
  flex-direction: row;
  flex:           1;
`

const mapStateToProps = (state) => ({
  brands:     getBrandList(state),
  categories: getCategoryList(state),
  error:      getProductError(state),
  fetching:   getProductsFetching(state),
  products:   getProductList(state),
})

const mapDispatchToProps = (dispatch) => ({
  getProducts: (params) => dispatch(requestProductList(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Catalog)
