/* Global imports */
import React, { useEffect, useState } from 'react'
import { bool, func } from 'prop-types'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { connect } from 'react-redux'

/* Local imports */
import InputForm from './InputForm'
import Text from '../Text'
import { loginScreen } from '../../definitions/colors'
import { isEmpty } from 'ramda'

import {
  getFetching,
  getError,
  requestLogin as loginRequest,
} from 'Reducers/user'

/* Component definition */
const LoginForm = ({ loginError, fetching, login }) => {
  const INITIAL_STATE = {
    password: '',
    username: '',
  }

  const [ form, setFormField ] = useState(INITIAL_STATE)
  const [ error, setError ] = useState(null)

  useEffect(() => {
    if (loginError) {
      setError('Login error')
    }
  }, [loginError])

  return (
    <Wrapper>
      <InputForm
        id="username"
        onChange={(value, field) => {
          setFormField({
            ...form,
            [field]: value,
          })

          setError(null)
        }}
        /* onEnter={() => validateForm(form, login, setError)} */
        title="Username"
      />
      <InputForm
        id="password"
        onChange={(value, field) => {
          setFormField({
            ...form,
            [field]: value,
          })

          setError(null)
        }}
        onEnter={() => validateForm(form, login, setError)}
        title="Password"
        type={'password'}
      />
      { error && <ErrrorMessage value={error} /> }
      <LoginButton
        disabled={fetching}
        onPress={() => validateForm(form, login, setError)}
      >
        <SText type="label" value="login.title" translate />
      </LoginButton>
    </Wrapper>
  )
}

/* PropTypes */
LoginForm.propTypes = {
  fetching:   bool,
  login:      func.isRequired,
  loginError: bool,
}

LoginForm.defaultProps = {}

/* Local utility functions */

const validateForm = (form, login, setError) => {
  if (!isEmpty(form.username) && !isEmpty(form.password)) {
    login(form.username, form.password)
  } else {
    setError('Not valid fields')
  }
}

/* Styles */
const Wrapper = styled(View)`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const LoginButton = styled(TouchableOpacity)`
  background-color: ${loginScreen.button};
  border-radius: 6px;
  margin-top: 36px;
  padding: 10px 10px;
  width: 80%;
  align-items: center;

  :focus {
    outline: none;
  }

  :hover {
    opacity: 0.9;
  }
`

const SText = styled(Text)`
  color: ${loginScreen.buttonText};;
`

const ErrrorMessage = styled(Text)`
  color: ${loginScreen.error};
  padding-top: 10px;
`
const mapStateToProps = state => ({
  fetching:   getFetching(state),
  loginError: getError(state),
})

const mapDispatchToProps = dispatch => ({
  login: (username, password) => dispatch(loginRequest({ password, username })),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
