import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import store, { persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import GlobalStyle from './components/GlobalStyle' // TODO make it work on mobile
import Routes from './screens'

export default function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyle />
        <Routes />
      </PersistGate>
    </StoreProvider>
  )
}
