/* Global imports */
import React, { useState, useEffect } from 'react'
import { View, FlatList, TouchableOpacity } from 'react-native'
import { arrayOf, func, string } from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { prop, prepend } from 'ramda'

/* Local imports */
import { catalogBrandSelector } from 'Definitions/colors'
import { brandShape, categoryShape } from 'Definitions/types'
import {
  requestList as requestBrandList,
  getList as getBrandList,
} from 'Reducers/brands'
import {
  requestList as requestProductList,
} from 'Reducers/products'
import Text from 'Components/Text'
import { getSubCategories } from 'Services/utils'
import {
  getList as getCategoryList,
} from 'Reducers/categories'
import BrandSelectorButtons from 'Components/Catalog/BrandSelectorButtons'

/* Component definition */
const BrandSelector = ({
  brands,
  categories,
  getBrands,
  getProducts,
  onSelectedBrand,
  onSelectedSubcategory,
  selected,
  selectedCategory,
}) => {
  const [ display, displaySelector ] = useState(null)
  useEffect(() => {
    getBrands(selectedCategory)
  }, [selectedCategory])

  const subCategories = getSubCategories(categories, selectedCategory)

  const extendedBrands = prepend({ _id: null, name: 'catalog.all' }, brands)

  return (
    <Wrapper>
      <Selector
        contentContainerStyle={{ alignItems: 'center', paddingBottom: 30 }}
        show={display}
        data={display === 'brand' ? extendedBrands : subCategories}
        keyExtractor={prop('_id')}
        renderItem={({ item }) =>
          <TouchableOpacity
            onPress={() => {
              if (display === 'brand') {
                selectBrand(item, getProducts, displaySelector, onSelectedBrand, selectedCategory)
              } else {
                selectSubcategory(item, getProducts, displaySelector, onSelectedSubcategory)
              }
            }}>
            <SText
              type="lead"
              value={item.name}
              fontWeight={'bold'}
              selected={selected === item._id}
              translate={item.name === 'catalog.all'}
            />
          </TouchableOpacity>
        }
      />
      { brands.length > 0 && <BrandSelectorButtons
        isActive={display}
        subCategories={subCategories}
        onClick={selection => displaySelector(selection !== display ? selection : null)}
      /> }
    </Wrapper>
  )
}

/* PropTypes */
BrandSelector.propTypes = {
  brands:                arrayOf(brandShape),
  categories:            arrayOf(categoryShape),
  getBrands:             func.isRequired,
  getProducts:           func.isRequired,
  onSelectedBrand:       func.isRequired,
  onSelectedSubcategory: func.isRequired,
  selected:              string,
  selectedCategory:      string,
}
BrandSelector.defaultProps = {}

/* Local utility functions */
const selectBrand = (brand, getProducts, displaySelector, onSelectedBrand, categorySelected) => {
  const params = brand._id ? { brand: brand._id } : { category: categorySelected }

  onSelectedBrand(brand._id)
  getProducts(params)
  displaySelector(null)
}

const selectSubcategory = (subcategory, getProducts, displaySelector, onSelectedSubcategory) => {
  onSelectedSubcategory(subcategory._id)
  getProducts({ category: subcategory._id })
  displaySelector(null)
}

/* Styles */
const Wrapper = styled(View)`
`

const Selector = styled(FlatList)`
  height: ${p => p.show ? '200px' : '0px'};
  border-top-color: ${catalogBrandSelector.border};
  border-top-width: ${p => p.show ? '2px' : '0px'};
  padding-top: ${p => p.show ? '20px' : '0px'};
`

const SText = styled(Text)`
  color: ${p => p.selected ? catalogBrandSelector.activeItem : catalogBrandSelector.text};
  font-weight: ${p => p.selected ? 'bold' : 'normal'};
`

const mapStateToProps = (state) => ({
  brands:     getBrandList(state),
  categories: getCategoryList(state),
})

const mapDispatchToProps = (dispatch) => ({
  getBrands:   (category) => dispatch(requestBrandList({ category })),
  getProducts: (params) => dispatch(requestProductList(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandSelector)
