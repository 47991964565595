import React from 'react'
import { Text, View } from 'react-native'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import { AnimatedSwitch as Switch } from 'react-router-transition'
import { compose } from 'crocks'
import { connect } from 'react-redux'

/* Local imports */
import SearchScreen from './SearchScreen'
import CatalogScreen from './CatalogScreen'
import SliderScreen from './SliderScreenWeb'
import ProductScreen from './ProductScreen'
import LoginScreen from './LoginScreen'
import NotificationsScreen from './NotificationsScreen'
import MarketingResourcesScreen from './MarketingResourcesScreen'
import MainFrame from 'Components/MainFrame'
import SyncScreen from './SyncScreen'
import DisclaimerScreen from './DisclaimerScreen'
import './switch-wrapper.css'

import {
  getAccepted,
} from 'Reducers/disclaimer'

const NoMatch = () => (
  <View><Text>NoMatch</Text></View>
)

const DisclaimerRouter = ({ component: Component, isDisclaimerAccepted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => isDisclaimerAccepted === true
        ? <MainFrame><Component /></MainFrame>
        : <Redirect to={{ pathname: '/disclaimer', state: { from: props.location } }} />}
    />
  )
}

const mapStateToProps = (state) => ({
  isDisclaimerAccepted: getAccepted(state),
})

const enhanceComponent = compose(
  connect(mapStateToProps),
)

const PrivateRoute = enhanceComponent(DisclaimerRouter)

const Routes = () => (
  <Router>
    <Switch
      className="switch-wrapper"
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
    >
      <PrivateRoute path="/" exact component={SliderScreen} />
      <PrivateRoute path="/catalog/category/:categoryId" exact component={CatalogScreen} />
      <PrivateRoute path="/catalog/search" exact component={SearchScreen} />
      <PrivateRoute path="/catalog/category/:categoryId/brand/:brandId" exact component={CatalogScreen} />
      <PrivateRoute path="/product/:productId?" exact component={ProductScreen} />
      <PrivateRoute path="/login" exact component={LoginScreen} />
      <PrivateRoute path="/notifications" exact component={NotificationsScreen} />
      <PrivateRoute path="/marketingResources" exact component={MarketingResourcesScreen} />
      <PrivateRoute path="/sync" exact component={SyncScreen} />
      <Route path="/disclaimer" exact component={DisclaimerScreen} />
      <Route component={NoMatch} />
    </Switch>
  </Router>
)

export default Routes
