/* Global imports */
import React from 'react'
import { arrayOf, func, string } from 'prop-types'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'

/* Local imports */
import { catalogContent } from 'Definitions/colors'
import { categoryShape } from 'Definitions/types'
import Text from 'Components/Text'

/* Component definition */
const CategoryFilter = ({ items, onSelect, parent }) => {
  return (
    <Wrapper>
      <Filter key="VerTodos" onPress={() => onSelect(parent)}>
        <SText
          value="Ver todos"
          type="lead"
        />
      </Filter>
      {
        items.map((item) => {
          return (
            <Filter key={item._id} onPress={() => onSelect(item._id)}>
              <SText
                selected={false}
                value={item.name}
                type="lead"
              />
            </Filter>
          )
        })
      }
    </Wrapper>
  )
}

/* PropTypes */
CategoryFilter.propTypes = {
  items:    arrayOf(categoryShape).isRequired,
  onSelect: func.isRequired,
  parent:   string,
}

CategoryFilter.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: auto;
  min-height: 50%;
  padding: 0 60px 0 20px;
  border-right-color: #DCDCDC;
  border-right-width: 1px;
`

const Filter = styled(TouchableOpacity)`
  display: flex;
  margin-bottom: 18px;
  padding-bottom: 2px;

  :hover {
    opacity: 0.7;
  }
`

const SText = styled(Text)`
  border-bottom-color: ${(props) => props.selected ? `${catalogContent.selected}` : 'white'}
  border-bottom-width: ${(props) => props.selected ? '1px' : '0'}
`

export default CategoryFilter
