/* Global imports */
import React, { useEffect, useState } from 'react'
import { TouchableOpacity, ImageBackground, Dimensions, Platform, View } from 'react-native'
import { array, func, object } from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isMobileOnly } from 'react-device-detect'
import { withRouter } from 'react-router-dom'

/* Local imports */
import Slides from 'Components/Slides'
import { request as requestSlides, getSlides as slidesSelector } from 'Reducers/sliders'
import { tabMenu } from 'Assets/images'
import Text from 'Components/Text'

const { width } = Dimensions.get('window')
/* Component definition */
const SliderScreen = ({ getSlides, slides, history }) => {
  const [ slide, setSlide ] = useState(0)

  useEffect(() => {
    getSlides()
  }, [])

  useEffect(() => {
    const interval = setTimeout(() => {
      setSlide(slide < slides.length - 1 ? slide + 1 : 0)
    }, 5000)

    return () => {
      clearTimeout(interval)
    }
  })

  if (Platform.OS === 'web' && !isMobileOnly) {
    return <Slides slides={slides} slide={slide} />
  }

  return (
    <Wrapper>
      <Slides slides={slides} slide={slide} />
      <ImageWrapper
        activeOpacity={1}
        onPress={() => history.push('catalog/category/5de788b9eab5a455a777a67e')}>
        <SImage source={tabMenu}>
          <SText color={'white'} value={'menu.catalog'} translate />
        </SImage>
      </ImageWrapper>
    </Wrapper>
  )
}

/* PropTypes */
SliderScreen.propTypes = {
  getSlides:  func.isRequired,
  history:    object,
  navigation: object,
  slides:     array.isRequired,
}

SliderScreen.defaultProps = {
  slides: [],
}

/* Local Styled Components */
const Wrapper = styled(View)`
  flex: 1;
`

const ImageWrapper = styled(TouchableOpacity)`
  position: absolute;
  bottom: 0;
  background-color: transparent;
  height: 100px;
  width: 100%;
`

const SImage = styled(ImageBackground)`
  height: 110px;
  width: ${width};
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const SText = styled(Text)`
  padding-top: 70px;
  text-align: center;
`

const mapStateToProps = (state) => {
  return {
    slides: slidesSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSlides: () => dispatch(requestSlides()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SliderScreen))
