import autodux from 'autodux'

export const {
  reducer,
  initial,
  slice,
  actions: {
    failureList,
    failureUnread,
    failureMarkRead,
    requestList,
    requestUnread,
    requestMarkRead,
    successList,
    successUnread,
    successMarkRead,
  },
  selectors: {
    getList,
    getError,
    getFetching,
    getUnreadCount,
    getUnreadError,
    getUnreadFetching,
  },
} = autodux({
  // No need to implement switching logic -- it's
  // done for you.
  actions: {
    failureList:     (state) => ({ ...state, error: true, fetching: false }),
    failureMarkRead: (state) => ({ ...state, errorMarkRead: true, fetchingMarkRead: false }),
    failureUnread:   (state) => ({ ...state, errorUnread: true, fetchingUnread: false }),
    requestList:     (state) => ({ ...state, error: false, fetching: true }),
    requestMarkRead: (state) => ({ ...state, errorMarkRead: false, fetchingMarkRead: true }),
    requestUnread:   (state) => ({ ...state, errorUnread: false, fetchingUnread: true }),
    successList:     (state, list) => ({ ...state, error: false, fetching: false, list }),
    successMarkRead: (state) => ({ ...state, errorMarkRead: false, fetchingMarkRead: false, unreadCount: 0 }),
    successUnread:   (state, unreadCount) => ({ ...state, errorUnread: false, fetchingUnread: false, unreadCount }),
  },

  // The initial value of your reducer state
  initial: {
    error:            false,
    errorMarkRead:    false,
    errorUnread:      false,
    fetching:         false,
    fetchingMarkRead: false,
    fetchingUnread:   false,
    list:             [],
    unreadCount:      0,
  },

  // No need to select the state slice -- it's done for you.
  selectors: {
  },

  // the slice of state your reducer controls
  slice: 'notifications',
})
