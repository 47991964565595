import { put } from 'redux-saga/effects'

import { successList, successDetail, failureDetail, failureList } from 'Reducers/products'
import create from 'Services/api'

const api = create('products')

export function * getProductList({ payload = {} }) {
  const { brand, category, callback = () => null, search = '' } = payload

  try {
    const populate = ['awards', 'brand', 'marketingResources'].join(',')
    const result = yield api.getAll({ brand, category, populate, search })

    if (result.ok) {
      const { data } = result
      callback(data)
      yield put(successList(data))
    } else {
      yield put(failureList(result.originalError))
    }
  } catch (e) {
    yield put(failureList(e))
  }
}

export function * getProductDetail({ payload: id }) {
  try {
    const populate = ['awards', 'brand', 'marketingResources'].join(',')
    const result = yield api.getOne(id, { populate })

    if (result.ok) {
      yield put(successDetail(result.data))
    } else {
      yield put(failureDetail(result.originalError))
    }
  } catch (e) {
    yield put(failureDetail(e))
  }
}
