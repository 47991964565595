/* Global imports */
import React from 'react'
import { Text } from 'react-native'
import { bool, oneOf, string } from 'prop-types'
import styled from 'styled-components'
import I18n from 'Assets/lang'

/* Local imports */
import { main, secondary } from '../definitions/fonts'
import { text } from '../definitions/colors'
import { gaps } from '../definitions/measures'

/* Component definition */
const CustomText = ({ className, color, gap, translate, type, value, ...props }) => {
  const getValue = getTranslator(translate)

  switch (type) {
    case 'caption':
      return <Caption className={className} color={color} gap={gap} {...props}>
        { getValue(value) }
      </Caption>
    case 'display1':
      return <Display1 className={className} color={color} gap={gap} {...props}>
        { getValue(value).toUpperCase() }
      </Display1>
    case 'display2':
      return <Display2 className={className} color={color} gap={gap} {...props}>
        { getValue(value).toUpperCase() }
      </Display2>
    case 'display3':
      return <Display3 className={className} color={color} gap={gap} {...props}>
        { getValue(value) }
      </Display3>
    case 'display4':
      return <Display4 className={className} color={color} gap={gap} {...props}>
        { getValue(value) }
      </Display4>
    case 'headline':
      return <Headline className={className} color={color} gap={gap} {...props}>
        { getValue(value).toUpperCase() }
      </Headline>
    case 'lead':
      return <Lead className={className} color={color} {...props}>
        { getValue(value) }
      </Lead>
    default:
      return <Body className={className} color={color} gap={gap} {...props}>
        { getValue(value) }
      </Body>
  }
}

/* PropTypes */
CustomText.propTypes = {
  className: string,
  color:     string,
  gap:       oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl']),
  translate: bool,
  type:      string,
  value:     string,
}

CustomText.defaultProps = {
  translate: false,
  type:      'body',
}

/* Local utility functions */

// Translation :: { lang :: String, value :: String }

// getValue :: ( String | [ Translation ], Boolean ) -> String
const getTranslator =
      shouldTranslate => value =>
        shouldTranslate ? I18n.t(value) : value

// getGap :: Object -> String
const getGap = ({ gap }) => {
  switch (gap) {
    case 'xs':
      return gaps.xs
    case 's':
      return gaps.s
    case 'm':
      return gaps.m
    case 'l':
      return gaps.l
    case 'xl':
      return gaps.xl
    case 'xxl':
      return gaps.xxl
    default:
      return '0'
  }
}

/* Local Styled components */
const Caption = styled(Text)`
  color:          ${p => p.color ? p.color : text.caption};
  font-family:    ${main};
  font-size:      10px;
  font-weight:    normal;
  letter-spacing: 0.17px;
  line-height:    16px;
`

const Display1 = styled(Text)`
  color:          ${p => p.color ? p.color : text.caption};
  font-family:    ${main};
  font-size:      24px;
  font-weight:    normal;
`

const Display2 = styled(Text)`
  color:          ${p => p.color ? p.color : text.caption};
  font-family:    ${main};
  font-size:      26px;
  font-weight:    bold;
  line-height:    48px;
`

const Display3 = styled(Text)`
  color:          ${p => p.color ? p.color : text.caption};
  font-family:    ${secondary};
  font-size:      24px;
  font-weight:    normal;
  letter-spacing: 2px;
  line-height:    56px;
`

const Display4 = styled(Text)`
  color:          ${p => p.color ? p.color : text.caption};
  font-family:    ${secondary};
  font-size:      32px;
  font-weight:    normal;
  line-height:    64px;
`

const Headline = styled(Text)`
  color:          ${p => p.color ? p.color : text.caption};
  font-family:    ${main};
  font-size:      18px;
  font-weight:    bold;
  line-height:    32px;
  margin-bottom:  ${getGap};
`

const Lead = styled(Text)`
  color:          ${p => p.color ? p.color : text.caption};
  font-family:    ${secondary};
  font-size:      20px;
  font-weight:    normal;
  line-height:    32px;
`

const Body = styled(Text)`
  color:          ${p => p.color ? p.color : text.caption};
  font-family:    ${main};
  font-size:      16px;
  font-weight:    normal;
  line-height:    24px;
  margin-bottom: ${getGap};
`

export default CustomText
