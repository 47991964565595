/* Global imports */
import React, { useEffect } from 'react'
import { object, string } from 'prop-types'
import { Platform, View } from 'react-native'
import styled from 'styled-components'

/* Local imports */
import LoginForm from 'Components/Login/LoginForm'
import Image from 'Components/Image'
import { blueLogo } from 'Assets/images'
import { gaps } from 'Definitions/measures'

/* Component definition */
const LoginScreen = ({ history, navigation, token }) => {
  useEffect(() => {
    if (token) {
      if (Platform.OS === 'web') {
        history.push('/marketingResources')
      } else {
        navigation.push('MarketingResourcesScreen')
      }
    }
  }, [token])

  return (
    <Wrapper>
      {
        Platform.OS === 'web' && <Logo
          source={blueLogo}
          mHeight={100}
          mWidth={100}
        />
      }
      <LoginForm />
    </Wrapper>
  )
}

/* PropTypes */
LoginScreen.propTypes = {
  history:    object,
  navigation: object,
  token:      string,
}

LoginScreen.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const Logo = styled(Image)`
  height: 100px;
  padding-bottom: ${gaps.xs};
`

export default LoginScreen
