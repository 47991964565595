/* Global imports */
import React from 'react'
import { bool, element, func } from 'prop-types'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'

/* Local imports */
import { icons } from 'Assets/images'
import Image from 'Components/Image'
import { gaps } from 'Definitions/measures'

const checkboxes = icons.checkboxes

/* Component definition */
const Checkbox = ({ active, contentComponent, onClick }) => {
  return (
    <Wrapper>
      <TouchableOpacity onPress={onClick} activeOpacity={1}>
        <SImage
          resizeMode="contain"
          mWidth={20}
          mHeight={20}
          source={active ? checkboxes.active : checkboxes.inactive}
        />
      </TouchableOpacity>

      { contentComponent }

    </Wrapper>
  )
}

/* PropTypes */
Checkbox.propTypes = {
  active:           bool,
  contentComponent: element.isRequired,
  onClick:          func.isRequired,
}
Checkbox.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`
  flex-direction: row;
  padding-bottom: ${gaps.xs};
`

const SImage = styled(Image)`
  width: 25px;
  align-self: center;
`

export default Checkbox
