/* Global imports */
import React from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import { Image } from 'react-native'

/* Local imports */
import { mainLogo } from '../../assets/images'

/* Component definition */
const MainLogo = ({ onClick }) => (
  <SImage
    source={mainLogo}
    alt="Logo Caballero"
    resizeMode="contain"
    onClick={onClick}
  />
)

MainLogo.propTypes = {
  onClick: func,
}

MainLogo.defaultProps = {
  onClick: () => void 0,
}

/* Local Styled Components */
const SImage = styled(Image)`
  flex:   1;
  height: 40px;
  max-width: 170px;
  padding: 8px;
  align-self: center;

  :hover {
    cursor: pointer;
  }
`

/* Local utility functions */

export default MainLogo
