/* Global imports */
import React from 'react'
import { arrayOf, bool } from 'prop-types'
import { ActivityIndicator, View } from 'react-native'
import styled from 'styled-components'

/* Local imports */
import { productShape } from 'Definitions/types'
import { catalogContent } from 'Definitions/colors'
import BottleSliderList from 'Components/Catalog/BottleSliderList'

/* Component definition */
const BottleSlider = ({ fetching, products }) => {
  // useEffect(() => { getProducts() }, [])

  if (fetching) {
    return (
      <SpinnerWrapper>
        <ActivityIndicator
          size="large"
          color={catalogContent.spinner}
        />
      </SpinnerWrapper>
    )
  }

  return (
    <Wrapper>
      <BottleSliderList products={products} resizeMode={'contain'} />
    </Wrapper>
  )
}

/* PropTypes */
BottleSlider.propTypes = {
  fetching: bool,
  products: arrayOf(productShape),
}

BottleSlider.defaultProps = {
  products: [],
}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`
  align-items:    flex-end;
  display:        flex;
  flex-direction: row;
  flex:           1 1 auto;
  height: 100%;
`

const SpinnerWrapper = styled(View)`
  flex: 1;
  justify-content: center;
  align-self: center;
`

export default BottleSlider
