/* Global imports */
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { arrayOf, object, func } from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'crocks'
import { withRouter } from 'react-router-dom'

/* Local imports */
import BottomItem from './BottomItem'
import { bottomBar } from 'Definitions/colors'
import { secondary } from 'Definitions/fonts'
import { footerHeight } from 'Definitions/measures'
import {
  requestList as requestCategoryList,
  getList as getCategoryList,
} from 'Reducers/categories'
import { categoryShape } from 'Definitions/types'

/* Component definition */
const BottomBar = ({ categories, getCategories, history }) => {
  useEffect(() => { getCategories({ onlyFirst: true }) }, [])

  return (
    <Wrapper key={history.location.pathname}>
      { categories
        .filter(c => !c.parent)
        .map(
          (category) =>
            <BottomItem
              key={category._id}
              category={category} />
        ) }
    </Wrapper>
  )
}

/* Local utilities */

/* Local Styled Components */
const Wrapper = styled(View)`
  background-color: ${bottomBar.background};
  display:          flex;
  flex:             0 1 ${footerHeight};
  font-family:      ${secondary};
  justify-content:  space-between;
  margin:           0;
  flex-direction:    row;
`

/* PropTypes */
BottomBar.propTypes = {
  categories:    arrayOf(categoryShape),
  getCategories: func.isRequired,
  history:       object,
}

BottomBar.defaultProps = {
  categories: [],
}

const mapStateToProps = (state) => ({
  categories: getCategoryList(state),
})

const mapDispatchToProps = (dispatch) => ({
  getCategories: (query) => dispatch(requestCategoryList(query)),
})

const enhanceComponent = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)

export default enhanceComponent(BottomBar)
