import { put } from 'redux-saga/effects'

import { success, failure } from 'Reducers/sliders'

import api from 'Services/api'

export function * getSlides({ payload = {} }) {
  const { callback = () => null } = payload
  try {
    const result = yield api('slides').getAll()

    if (result.ok) {
      const { data } = result
      callback(data)
      yield put(success(data))
    } else {
      yield put(failure(result))
    }
  } catch (e) {
    yield put(failure(e))
  }
}
