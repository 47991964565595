import React from 'react'
import { func } from 'prop-types'
import { TouchableOpacity, View } from 'react-native'
import { slide as Menu } from 'react-burger-menu'
import { NavLink, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { compose } from 'crocks'
import { connect } from 'react-redux'
import { prop, is } from 'ramda'

/* Local imports */
import Text from 'Components/Text'
import { general, topNav } from 'Definitions/colors'
import {
  getToken,
  requestLogout as logoutRequest,
} from 'Reducers/user'

const menuItems = [
  {
    link:  '/',
    title: 'menu.home',
  },
  {
    link:   '/login',
    logged: false,
    title:  'menu.comercialArea',
  },
  {
    link:  '/marketingResources',
    title: 'menu.marketingAndTraining',
  },
  {
    logged: true,
    title:  'menu.exit',
    type:   'logout',
  },
]

const MainMenu = ({ logout, token }) => {
  return (
    <>
      <View key={token} style={{ flex: 1 }} />
      <Menu isOpen={false} styles={menuStyle}>
        {
          menuItems.map((item, index) => {
            if (prop('logged', item) !== undefined && item.logged !== is(String, token)) {
              return
            }

            if (item.type === 'logout') {
              return (
                <STouchableOpacity key={index} onPress={() => logout()}>
                  <SText value={item.title} translate />
                </STouchableOpacity>
              )
            }

            return (
              <SNavLink
                key={index}
                to={item.link}
              >
                <SText type="body" value={item.title} translate />
              </SNavLink>
            )
          })
        }
      </Menu>
    </>
  )
}

MainMenu.propTypes = {
  logout: func.isRequired,
}

const SNavLink = styled(NavLink)`
  border-bottom: 1px solid ${topNav.menu};
  width: 100%;
  padding: 20px 0 25px 10px;

  :hover {
    opacity: ${general.hoverOpacity};
  }
`

const STouchableOpacity = styled(TouchableOpacity)`
  border-bottom: 1px solid ${topNav.menu};
  width: 100%;
  padding: 20px 0 25px 10px;

  :hover {
    opacity: ${general.hoverOpacity};
  }
`

const SText = styled(Text)`
  color: ${topNav.menu};
`

var menuStyle = {
  bmBurgerBars: {
    background: topNav.menu,
    height:     '12%',
  },
  bmBurgerButton: {
    height:   '25px',
    left:     '12px',
    position: 'absolute',
    top:      '20px',
    width:    '30px',
  },
  bmCross: {
    background: topNav.cross,
    height:     '30px',
  },
  bmCrossButton: {
    height: '60px',
    left:   '20px',
    right:  'auto',
    top:    '12px',
    width:  '30px',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmItemList: {
    color:   topNav.text,
    padding: '35px 0px',
  },
  bmMenu: {
    background: topNav.background,
    fontSize:   '1.15em',
    padding:    '2.5em 1em 0',
  },
  bmMenuWrap: {
    left:  0,
    right: 'auto',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    left:       0,
  },
}

const mapStateToProps = state => {
  return {
    token: getToken(state),
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutRequest()),
})

const enhanceComponent = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)

export default enhanceComponent(MainMenu)
