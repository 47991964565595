const colors = {
  error:     'red',
  gray:      '#4A4A4A',
  lightGray: '#D7D7D7',
  mainBlue:  '#00293E',
  softGray:  '#EFEFEF',
  white:     '#FFFFFF',
  yellow:    '#FFB90B',
}

export const general = {
  hoverOpacity: 0.7,
}

export const loginScreen = {
  button:     colors.mainBlue,
  buttonText: colors.white,
  error:      colors.error,
  input:      colors.softGray,
}

export const navBar = {
  activeItemColor:   colors.yellow,
  background:        colors.mainBlue,
  inactiveItemColor: colors.white,
  text:              colors.white,
}

export const bottomBar = {
  activeItem: colors.yellow,
  background: colors.white,
  bottomItem: colors.softGray,
  text:       colors.gray,
}

export const topNav = {
  background: colors.mainBlue,
  cross:      colors.softGray,
  menu:       colors.white,
  text:       colors.softGray,
}

export const catalogTopBar = {
  activeItem: colors.yellow,
  border:     colors.softGray,
  text:       colors.gray,
}

export const catalogBrandSelector = {
  activeItem: colors.mainBlue,
  border:     colors.softGray,
  text:       colors.gray,
}

export const catalogBottomBar = {
  activeItem: colors.yellow,
  background: colors.mainBlue,
  border:     colors.softGray,
  text:       colors.white,
}

export const catalogContent = {
  border:   colors.softGray,
  selected: colors.yellow,
  spinner:  colors.mainBlue,
  text:     colors.gray,
}

export const mainSlider = {
  border: colors.mainBlue,
  text:   colors.mainBlue,
}

export const iconButton = {
  text: colors.mainBlue,
}

export const forceDownloadApp = {
  background: colors.mainBlue,
  text:       colors.white,
}

export const notificationsScreen = {
  date:        colors.yellow,
  description: colors.gray,
}

export const productDetail = {
  awardsRuler:   colors.yellow,
  border:        colors.lightGray,
  resourcesMenu: {
    background: colors.white,
    item:       {
      borderBottom: colors.lightGray,
      fileType:     colors.yellow,
    },
  },
  text: colors.mainBlue,
}

export const text = {
  caption: colors.mainBlue,
}

export const disclaimer = {
  main:  colors.mainBlue,
  white: colors.white,
}

export const map = {
  border: colors.yellow,
  hRule:  colors.lightGray,
  marker: colors.mainBlue,
}
