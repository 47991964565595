import { put } from 'redux-saga/effects'

import {
  successList,
  failureList,
  successUnread,
  failureUnread,
  successMarkRead,
  failureMarkRead,
} from 'Reducers/notifications'

import api from 'Services/api'

export function * getNotifications() {
  try {
    const result = yield api('notifications').getAll()

    if (result.ok) {
      yield put(successList(result.data))
    } else {
      yield put(failureList(result))
    }
  } catch (e) {
    yield put(failureList(e))
  }
}

export function * getUnreadNotifications() {
  try {
    const result = yield api('notifications/unread').getAll()

    if (result.ok) {
      yield put(successUnread(result.data))
    } else {
      yield put(failureUnread(result))
    }
  } catch (e) {
    yield put(failureList(e))
  }
}

export function * markReadNotifications() {
  try {
    const result = yield api('notifications/unread').post()

    if (result.ok) {
      yield put(successMarkRead())
    } else {
      yield put(failureMarkRead(result))
    }
  } catch (e) {
    yield put(failureList(e))
  }
}
