/* Global imports */
import React from 'react'
import { Dimensions, Platform, TouchableOpacity } from 'react-native'
import { element, object, number, string } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import Image from 'Components/Image'
import { productShape } from 'Definitions/types'
import { TransitionHOCs } from '.'
import { isMobileOnly } from 'react-device-detect'

const { height } = Dimensions.get('window')

/* Component definition */
const Bottle = ({
  className,
  history,
  imageHeight,
  mHeight,
  mWidth,
  product,
  resizeMode,
  navigation,
  Footer,
}) => {
  if (Platform.OS === 'web' && !isMobileOnly) {
    const { CSSTransition, TransitionGroup } = TransitionHOCs

    return (
      <TransitionGroup>
        <CSSTransition classNames="item" timeout={1500}>
          <Wrapper className={className} onPress={() => _goToDetail(history, navigation, product)}>
            <SImage
              alt={product.name}
              source={product.imageUrl}
              mHeight={mHeight}
              mWidth={mWidth}
              imageHeight={imageHeight}
              resizeMode={resizeMode}
            />
            { Footer }
          </Wrapper>
        </CSSTransition>
      </TransitionGroup>
    )
  }

  return (
    <Wrapper className={className} onPress={() => _goToDetail(history, navigation, product)}>
      <SImage
        alt={product.name}
        source={product.imageUrl}
        mHeight={mHeight}
        mWidth={mWidth}
        imageHeight={imageHeight}
        resizeMode={resizeMode}
      />
      { Footer }
    </Wrapper>
  )
}

/* PropTypes */
Bottle.propTypes = {
  Footer:      element,
  className:   string,
  history:     object,
  imageHeight: string,
  mHeight:     number,
  mWidth:      number,
  navigation:  object,
  product:     productShape,
  resizeMode:  string,
}

Bottle.defaultProps = {}

/* Local utility functions */

const _goToDetail = (history, navigation, product) => {
  if (Platform.OS === 'web') {
    history.push({ pathname: `/product/${product._id}`, product })
  } else if (navigation) {
    navigation.push('ProductDetail', { product: product })
  }
}

/* Styles */
const Wrapper = styled(TouchableOpacity)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SImage = styled(Image)`
  max-width: ${height / 3}px;
  height: ${p => p.imageHeight ? p.imageHeight : null};
  ${p => Platform.OS === 'web' && p.resizeMode ? `object-fit: ${p.resizeMode}` : null};
`

export default Bottle
