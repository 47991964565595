/* Global imports */
import React from 'react'
import { string } from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'crocks'
import { Platform, View } from 'react-native'
import styled from 'styled-components'
import { Col, Container, Row } from 'reactstrap'

/* Local imports */
import { getToken } from 'Reducers/user'
import { productShape } from 'Definitions/types'
import Image from 'Components/Image'
import {
  renderAwards,
  renderDataTables,
  renderTable,
  renderProductValue,
} from './utils'

/* Component definition */
const ProductDetail = ({ product, token }) => {
  const {
    analyticalData,
    argumentary,
    awards,
    description,
    imageUrl,
    logisticSheet,
    name,
    tastingNote,
  } = product

  const bottles = [
    {
      bottleGrossWeight: product.bottleGrossWeight,
      bottleHeight:      product.bottleHeight,
      bottleLength:      product.bottleLength,
      bottleNetWeight:   product.bottleNetWeight,
      bottleWidth:       product.bottleWidth,
    },
  ]

  const boxes = [
    {
      boxGrossWeight: product.boxGrossWeight,
      boxHeight:      product.boxHeight,
      boxLength:      product.boxLength,
      boxNetWeight:   product.boxNetWeight,
      boxUnits:       product.boxUnits,
      boxWidth:       product.boxWidth,
    },
  ]

  const pallets = [
    {
      palletBoxesLayer:  product.palletBoxesLayer,
      palletBoxesPallet: product.palletBoxesPallet,
      palletHeight:      product.palletHeight,
      palletLayerPallet: product.palletLayerPallet,
      palletType:        product.palletType,
      palletWeight:      product.palletWeight,
    },
  ]

  return (
    <Wrapper>
      <ImageCol md="4">
        <SImage
          alt={name}
          source={imageUrl}
          resizeMode="cover"
        />
      </ImageCol>
      <DescriptionCol>
        <Container fluid>
          <Row>
            <Col>
              { renderAwards(awards) }
            </Col>
          </Row>
          <Row>
            <Col>
              { /* Description */ }
              { renderProductValue(description, 'description') }

              { /* Notes */ }
              { renderProductValue(tastingNote, 'tastingNotes') }

              { /* Arguments */ }
              { renderProductValue(argumentary, 'arguments') }

            </Col>
          </Row>
          { renderTable(token, logisticSheet, 'productDetail.tables.logisticSheet', 'logisticSheet') }
          { renderTable(token, analyticalData, 'productDetail.tables.analyticalData', 'analyticalData') }
          { bottles.length > 0 && renderDataTables(token, bottles, 'productDetail.tables.bottle', 'bottle') }
          { boxes.length > 0 && renderDataTables(token, boxes, 'productDetail.tables.box', 'box') }
          { pallets.length > 0 && renderDataTables(token, pallets, 'productDetail.tables.pallet', 'pallet') }
        </Container>
      </DescriptionCol>
    </Wrapper>
  )
}

/* Local utility functions */

/* Local Styled Components */
const Wrapper = styled(View)`
  display:        flex;
  flex-direction: row;
  height:         100%;
`

const DescriptionCol = styled(Col)`
  overflow: auto;
`

const ImageCol = styled(Col)`
  align-items:     center;
  display:         flex;
  justify-content: center;
`

const SImage = styled(Image)`
  height: 100%;
  ${Platform.OS === 'web' ? `object-fit: contain` : null};
`

/* PropTypes */
ProductDetail.propTypes = {
  product: productShape.isRequired,
  token:   string,
}

ProductDetail.defaultProps = {}

const mapStateToProps = state => {
  return {
    token: getToken(state),
  }
}

const enhanceComponent = compose(
  connect(mapStateToProps),
)

export default enhanceComponent(ProductDetail)
