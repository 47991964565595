/* Global imports */
import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { View } from 'react-native'

/* Local imports */
import Image from 'Components/Image'
import { brandShape } from 'Definitions/types'

/* Component definition */
const BrandItem = ({ brand, className }) => {
  return (
    <Wrapper>
      <SImage
        alt={brand.name}
        className={className}
        source={brand.logoUrl}
        mHeight={50}
        mWidth={50}
      />
    </Wrapper>
  )
}

/* PropTypes */
BrandItem.propTypes = {
  brand:     brandShape,
  className: string,
}

BrandItem.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`
  align-self: center;
  padding: 10px;
`

const SImage = styled(Image)`
  max-height: 100px;
  max-width:  100px;
`

export default BrandItem
