/* Global imports */
import React, { useEffect, useState } from 'react'
import { bool, object, func } from 'prop-types'
import {
  ImageBackground,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native'
import styled from 'styled-components'

/* Local imports */
import { fullLogo, disclaimerBackground } from 'Assets/images'
import { gaps } from 'Definitions/measures'
import Text from 'Components/Text'
import Checkbox from 'Components/Checkbox'
import DisclaimerModal from 'Components/DisclaimerModal'
import Image from 'Components/Image'
import { disclaimer as disclaimerColors } from 'Definitions/colors'

/* Component definition */
const DisclaimerScreen = ({
  acceptDisclaimer,
  history,
  isDisclaimerAccepted,
  navigation,
}) => {
  useEffect(() => {
    if (isDisclaimerAccepted) {
      if (Platform.OS === 'web') {
        history.push('/')
      } else {
        navigation.navigate('App')
      }
    }
  }, [isDisclaimerAccepted])

  const [ checkboxes, setCheckboxex ] = useState({ age: false, conditions: false })
  const [ modalVisible, openModal ] = useState(false)

  return (
    <Wrapper source={disclaimerBackground} resizeMode="cover">
      <ContentWrapper>
        <Logo
          source={fullLogo}
          mHeight={100}
          mWidth={100}
          resizeMode="contain"
        />
        <CheckBoxexWrapper>
          <Checkbox
            active={checkboxes.conditions}
            contentComponent={
              <TouchableOpacity onPress={() => openModal(true)}>
                <CheckboxText underline="true" value="He leido las condiciones de uso" />
              </TouchableOpacity>
            }
            onClick={() => setCheckboxex({ ...checkboxes, conditions: !checkboxes.conditions })}
          />
          <Checkbox
            active={checkboxes.age}
            contentComponent={<CheckboxText value="Soy mayor de 18 años" />}
            onClick={() => setCheckboxex({ ...checkboxes, age: !checkboxes.age })}
          />
        </CheckBoxexWrapper>
        <AcceptButton
          disabled={!checkboxes.age || !checkboxes.conditions}
          onPress={() => acceptDisclaimer()}
        >
          <AcceptText
            value={'disclaimer.acceptButtonTitle'}
            translate
          />
        </AcceptButton>
      </ContentWrapper>

      <DisclaimerModal
        visible={modalVisible}
        onClick={(bool) => openModal(bool)}
      />
    </Wrapper>
  )
}

/* PropTypes */
DisclaimerScreen.propTypes = {
  acceptDisclaimer:     func.isRequired,
  history:              object,
  isDisclaimerAccepted: bool,
  navigation:           object,
}
DisclaimerScreen.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(ImageBackground)`
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
`

const ContentWrapper = styled(View)`
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
`

const Logo = styled(Image)`
  height: 100px;
`

const CheckBoxexWrapper = styled(View)`
  padding: ${gaps.m} 0;
`

const CheckboxText = styled(Text)`
  align-self: center;
  padding-left: ${gaps.xs};
  color: ${disclaimerColors.white};
  ${p => p.underline ? `text-decoration: underline;
    text-decoration-color: white;` : null};
`

const AcceptButton = styled(TouchableOpacity)`
  opacity: ${p => p.disabled ? '0.5' : '1'};
  border-color: ${disclaimerColors.white};
  border-width: 1px;
`

const AcceptText = styled(Text)`
  color: ${disclaimerColors.white};
  padding: ${gaps.xs} ${gaps.m};
`

export default DisclaimerScreen
