import { all, takeLatest, takeEvery } from 'redux-saga/effects'

export default function * () {
  yield all([
    takeEvery(
      require('Reducers/brands').requestList.type,
      require('./brands').getBrands
    ),
    takeLatest(
      require('Reducers/categories').requestList.type,
      require('./categories').getCategories
    ),
    takeLatest(
      require('Reducers/notifications').requestList.type,
      require('./notifications').getNotifications
    ),
    takeLatest(
      require('Reducers/notifications').requestUnread.type,
      require('./notifications').getUnreadNotifications
    ),
    takeLatest(
      require('Reducers/notifications').requestMarkRead.type,
      require('./notifications').markReadNotifications
    ),
    takeEvery(
      require('Reducers/products').requestList.type,
      require('./products').getProductList
    ),
    takeLatest(
      require('Reducers/products').requestDetail.type,
      require('./products').getProductDetail
    ),
    takeLatest(
      require('Reducers/sliders').request.type,
      require('./sliders').getSlides
    ),
    takeLatest(
      require('Reducers/user').requestLogin.type,
      require('./user').login
    ),
    takeLatest(
      require('Reducers/marketingResources').requestList.type,
      require('./marketingResources').getMarketingResources
    ),
  ])
}
