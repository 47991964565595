/* Global imports */
import React from 'react'
import { array, bool, number, oneOf, oneOfType, string } from 'prop-types'
import { Image, Platform } from 'react-native'
import { isMobileOnly } from 'react-device-detect'

/* Local imports */

/* Component definition */
const CustomImage = ({ source, alt, className, maxWidth, mHeight, mWidth, resizeMode }) => {
  let styles = {}

  if (Platform.OS === 'web') {
    if (maxWidth && isMobileOnly) {
      styles = { maxWidth: '100%' }
    }

    return <img alt={alt} className={className} src={source} style={styles} />
  }

  if (mHeight && mWidth) {
    styles = { height: mHeight, width: mWidth }
  }

  const getSource = (source) => typeof source === 'number' ? source : { uri: source }

  return <Image
    resizeMode={resizeMode}
    source={getSource(source)}
    style={styles}
  />
}

/* PropTypes */
CustomImage.propTypes = {
  alt:        oneOfType([array, string]),
  className:  string,
  mHeight:    number,
  mWidth:     number,
  maxWidth:   bool,
  resizeMode: oneOf(['contain', 'cover', 'stretch', 'center', 'repeat']),
  source:     oneOfType([number, string]).isRequired,
}

CustomImage.defaultProps = {}

/* Local utility functions */

/* Styles */

export default CustomImage
