/* Global imports */
import React, { useState } from 'react'
import { object } from 'prop-types'
import { Platform, View } from 'react-native'
import styled from 'styled-components'
import { isMobileOnly } from 'react-device-detect'

/* Local imports */
import Catalog from 'Components/Catalog'
import CategorySelector from 'Components/Catalog/CategorySelector'
import BrandSelector from 'Components/Catalog/BrandSelector'

/* Component definition */
const CatalogScreen = ({ match, navigation }) => {
  const [ selectedCategory, setSelectedCategory ] = useState(null)
  const [ selectedBrand, setSelectedBrand ] = useState(null)
  const [ selectedSubcategory, setSelectedSubcategory ] = useState(null)

  const { brandId = null, categoryId = null } = getParams(match)

  return (
    <Wrapper>
      <SCatalog
        navigation={navigation}
        categorySelected={selectedCategory || categoryId}
        brandSelected={brandId}
      />
      { Platform.OS !== 'web' || isMobileOnly
        ? <SelectorWrapper>
          <BrandSelector
            key={selectedCategory} // Force render when selectedCategory changes
            selectedCategory={selectedCategory}
            selected={selectedBrand || selectedSubcategory}
            onSelectedBrand={id => setSelectedBrand(id)}
            onSelectedSubcategory={id => {
              setSelectedBrand(null)
              setSelectedSubcategory(id)
            }}
          />
          <CategorySelector
            selected={selectedCategory}
            onSelect={id => {
              setSelectedCategory(id)
              setSelectedBrand(null)
              setSelectedSubcategory(null)
            }}
          />
        </SelectorWrapper>
        : null }
    </Wrapper>
  )
}

/* PropTypes */
CatalogScreen.propTypes = {
  match:      object,
  navigation: object,
}

CatalogScreen.defaultProps = {}

/* Local utility functions */

const getParams = match => {
  if (Platform.OS === 'web') {
    const { brandId = null, categoryId = null } = match.params

    return { brandId, categoryId }
  }

  return {}
}

/* Styles */
const Wrapper = styled(View)`
  flex: 1;
  position: relative;
`

const SCatalog = styled(Catalog)`
  position: absolute;
`

const SelectorWrapper = styled(View)`
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
`

export default CatalogScreen
