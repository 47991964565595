/* Global imports */
import React from 'react'
import { func, number } from 'prop-types'
import styled from 'styled-components'
import { Platform, View, Text, TouchableOpacity } from 'react-native'

/* Local imports */
import { icons } from 'Assets/images'
import Image from 'Components/Image'

import { navBar } from 'Definitions/colors'
/* Component definition */
const NotificationsIcon = ({ onClick, number }) => {
  return (
    <TouchableOpacity onPress={() => onClick()}>
      { number > 0 && <NumberWrapper>
        <SText>{ number }</SText>
      </NumberWrapper> }
      <SImage
        source={icons.notifications}
        mHeight={40}
        mWidth={40}
      />
    </TouchableOpacity>
  )
}

/* PropTypes */
NotificationsIcon.propTypes = {
  number:  number,
  onClick: func.isRequired,
}
NotificationsIcon.defaultProps = {
  number: 0,
}

/* Local utility functions */

/* Styles */
const NumberWrapper = styled(View)`
  position: absolute;
  background: ${navBar.activeItemColor};
  right: ${Platform.OS === 'web' ? '10px' : '2px'};
  top: ${Platform.OS === 'web' ? '10px' : '4px'};;
  border-radius: 15px;
`

const SText = styled(Text)`
  font-size: 8px;
  padding: ${Platform.OS === 'web' ? '1px 4px' : '1px 3px'};
  color: ${navBar.text};
`

const SImage = styled(Image)`
  flex: 1;
  height: 60px;
`

export default NotificationsIcon
