import autodux from 'autodux'

export const {
  reducer,
  initial,
  slice,
  actions: {
    setSync,
  },
  selectors: {
    getSync,
  },
} = autodux({
  // No need to implement switching logic -- it's
  // done for you.
  actions: {
    setSync: (state, payload) => ({ ...state, sync: payload }),
  },

  // The initial value of your reducer state
  initial: {
    sync: false,
  },

  // No need to select the state slice -- it's done for you.
  selectors: {
  },

  // the slice of state your reducer controls
  slice: 'sync',
})
