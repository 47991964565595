/* Global imports */
import React, { useEffect, useState } from 'react'
import { Platform, View } from 'react-native'
import {
  arrayOf,
  func,
  object,
} from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

/* Local imports */
import Search from 'Components/Search'
import SearchProductList from 'Components/SearchProductList'
import {
  getList as getProductList,
  getError as getProductError,
  getFetching as getProductsFetching,
  requestList as requestProductList,
} from 'Reducers/products'

import {
  productShape,
} from 'Definitions/types'

/* Component definition */
const SearchScreen = ({ getProducts, navigation, products }) => {
  const [ searchText, setSearchText ] = useState('')
  useEffect(() => {
    if (Platform.OS !== 'web') {
      const listener = navigation.addListener('willFocus', () => {
        getProducts({ search: searchText })
      })

      return () => { listener.remove() }
    }
  }, [])

  useEffect(() => {
    getProducts({ search: searchText })
  }, [searchText])

  return (
    <Wrapper>
      <Search onSearch={text => setSearchText(text)} />
      <SearchProductList products={products} />
    </Wrapper>
  )
}

/* PropTypes */
SearchScreen.propTypes = {
  getProducts: func.isRequired,
  navigation:  object,
  products:    arrayOf(productShape),
}
SearchScreen.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`
  ${Platform.OS === 'web' ? 'height: 100%; position: relative' : null};
  flex: 1;
`

const mapStateToProps = (state) => ({
  error:    getProductError(state),
  fetching: getProductsFetching(state),
  products: getProductList(state),
})

const mapDispatchToProps = (dispatch) => ({
  getProducts: (params) => dispatch(requestProductList(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchScreen)
