/* Global imports */
import React, { useEffect } from 'react'
import { arrayOf, func, string } from 'prop-types'
import { FlatList, View } from 'react-native'
import { connect } from 'react-redux'
import styled from 'styled-components'

/* Local imports */
import { catalogBottomBar } from 'Definitions/colors'
import CategorySelectorItem from './CategorySelectorItem'
import {
  requestList as requestCategoryList,
  getList as getCategoryList,
} from 'Reducers/categories'
import { categoryShape } from 'Definitions/types'

/* Component definition */
const CategoriesSelector = ({ categories, getCategories, onSelect, selected }) => {
  useEffect(() => { getCategories({ onlyFirst: true }) }, [])

  // If there is no category selected and there are categories then select the first category.
  useEffect(() => {
    if (!selected && categories.length > 0) {
      onSelect(categories[0]._id)
    }
  }, [categories])

  if (categories) {
    return (
      <Wrapper>
        <FlatList
          scrollEnabled={categories.length > 5}
          extraData={selected}
          keyExtractor={(item) => item._id}
          horizontal={true}
          data={categories}
          renderItem={({ item }) => renderItem(item, selected, onSelect)}
        >
        </FlatList>
      </Wrapper>
    )
  }

  return null
}

/* PropTypes */
CategoriesSelector.propTypes = {
  categories:    arrayOf(categoryShape),
  getCategories: func.isRequired,
  onSelect:      func.isRequired,
  selected:      string,
}

CategoriesSelector.defaultProps = {
  categories: [],
}

/* Local utility functions */
const renderItem = (category, selected, setSelected) => {
  return (
    <CategorySelectorItem
      id={category._id}
      name={category.name}
      visible={category.visible}
      active={selected && category._id === selected}
      onClick={() => setSelected(category._id)}
    />
  )
}
/* Styles */

const Wrapper = styled(View)`
  height: 50px;
  background-color: ${catalogBottomBar.background};
  width: 100%;
`

const mapStateToProps = (state) => ({
  categories: getCategoryList(state),
})

const mapDispatchToProps = (dispatch) => ({
  getCategories: (query) => dispatch(requestCategoryList(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSelector)
