import { put } from 'redux-saga/effects'
import { evolve, filter } from 'ramda'

import { successList, failureList } from 'Reducers/categories'

import api from 'Services/api'

export function * getCategories({ payload }) {
  try {
    const populate = ['subCategories'].join(',')
    const query = { ...payload, populate }
    const result = yield api('categories').getAll(query)

    if (result.ok) {
      yield put(successList(result.data.map(evolve({ subCategories: filter(x => x.visible) }))))
    } else {
      yield put(failureList(result))
    }
  } catch (e) {
    yield put(failureList(e))
  }
}
