/* Global imports */
import React from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { func, number, oneOf, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import Text from 'Components/Text'
import Image from 'Components/Image'
import { iconButton } from 'Definitions/colors'

/* Component definition */
const IconButton = ({ icon, onClick, text, type, textPosition }) => {
  return (
    <Wrapper onPress={onClick} position={textPosition}>
      <ItemWrapper>
        <SImage
          mHeight={50}
          mWidth={50}
          source={icon}
          alt={text}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Text value={text} type={type} translate />
      </ItemWrapper>
    </Wrapper>
  )
}

/* PropTypes */
IconButton.propTypes = {
  icon:         oneOfType([number, string]).isRequired,
  onClick:      func,
  text:         string.isRequired,
  textPosition: oneOf(['right', 'bottom']),
  type:         string,
}

IconButton.defaultProps = {
  textPosition: 'bottom',
}

/* Local utility functions */
// getTextPosition :: Object -> String
const getTextPosition =
      ({ position }) => {
        if (position === 'right') {
          return 'row'
        }

        return 'column'
      }

/* Local Styled Components */
const Wrapper = styled(TouchableOpacity)`
  flex-direction: ${getTextPosition};
  padding:        3px;

  :hover {
    opacity: 0.7
  }
`

const ItemWrapper = styled(View)`
  align-items:     center;
  justify-content: center;
  color:           ${iconButton.text};
`

const SImage = styled(Image)`
  flex: 1;
  ${Platform.OS === 'web' ? `object-fit: contain` : null};
`

export default IconButton
