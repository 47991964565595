/* Global imports */
import React from 'react'
import {
  Dimensions,
  ImageBackground,
  Platform,
  View,
} from 'react-native'
import { string } from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'crocks'
import { isMobileOnly } from 'react-device-detect'

/* Local imports */
import Text from 'Components/Text'
import { mainSlider } from 'Definitions/colors'

const { height } = Dimensions.get('window')

/* Component definition */
const Slide = ({ background, titleText, subtitle, description }) => {
  return (
    <Wrapper source={{ uri: background }}>
      <InfoWrapper>
        <TextWrapper>
          { renderTitle(titleText, subtitle, description) }
          <DescriptionWrapper>
            <Text value={description} />
          </DescriptionWrapper>
        </TextWrapper>
      </InfoWrapper>
    </Wrapper>
  )
}

/* PropTypes */
Slide.propTypes = {
  background:  string.isRequired,
  description: string,
  subtitle:    string,
  titleText:   string.isRequired,
}

Slide.defaultProps = {}

/* Local utility functions */
const renderTitle = (title, subtitle, description) => {
  if (isEmpty(title) && isEmpty(subtitle)) {
    return null
  }

  return (
    <TitleWrapper showBorder={(!isEmpty(title) || !isEmpty(subtitle)) && !isEmpty(description)}>
      <Text type="display1" value={title} />
      <Text type="display2" value={subtitle} />
    </TitleWrapper>
  )
}

/* Styles */
const Wrapper = styled(ImageBackground)`
  height: ${Platform.OS === 'web' && !isMobileOnly ? height - 135 : height - 110}px;
`

const InfoWrapper = styled(View)`
  align-items:    ${Platform.OS === 'web' && !isMobileOnly ? 'flex-end' : 'flex-start'};
  flex-direction: row;
  height:         ${Platform.OS === 'web' && !isMobileOnly ? height - 135 : height - 110}px;
  padding:        ${Platform.OS === 'web' && !isMobileOnly ? '40px' : '0'};
`

const TextWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
`

const TitleWrapper = styled(View)`
  border-right-color: ${mainSlider.text};
  border-right-width: ${({ showBorder }) => Platform.OS === 'web' && showBorder && !isMobileOnly ? '1px' : '0'};
  padding-left:       20px;
  padding:            ${Platform.OS === 'web' && !isMobileOnly ? '15px 50px' : '25px 15px'};
`

const DescriptionWrapper = styled(View)`
  max-width:    350px;
  padding-left: 25px;
  display: ${Platform.OS === 'web' && !isMobileOnly ? 'flex' : 'none'};
`

export default Slide
