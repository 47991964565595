import { create } from 'apisauce'
import { Platform } from 'react-native'
import { assign, concat, flip, isObject, safe, when } from 'crocks'

import {
  REACT_APP_SERVER_DOMAIN,
  REACT_APP_SERVER_BASE_PATH,
} from 'react-native-dotenv'

import store from '../store'

// NOTE: View https request in debugger for react-native
/* eslint-disable */
if (Platform.OS !== 'web') {
  GLOBAL.XMLHttpRequest = GLOBAL.originalXMLHttpRequest || GLOBAL.XMLHttpRequest
}
/* eslint-enable */

const fconcat = flip(concat)
const safeObject = safe(isObject)

export const apiUrl = process.env['REACT_APP_SERVER_DOMAIN'] || REACT_APP_SERVER_DOMAIN
const basePath = process.env['REACT_APP_SERVER_BASE_PATH'] || REACT_APP_SERVER_BASE_PATH || '/api'

if (!apiUrl) {
  throw Error('REACT_APP_SERVER_DOMAIN is not set. Please set the variable.')
}

const api = create({
  baseURL: apiUrl,
})

const getConfig = config => assign({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
}, safeObject(config).option({}))

// Add the base path to all 'relative' paths. Paths that start
// with / with be left unchanged:
const getPath = when(x => /^(?!\/)/.test(x), fconcat(basePath + '/'))

const getToken = () => store.getState().user.token

/* Exported Functions */

// function del(path, params, config) {
//   return api.delete(getPath(path), params, getConfig(config))
// }

function get(path, params, config) {
  return api.get(getPath(path), params, getConfig(config))
}

// function patch(path, data, config) {
//   return api.patch(getPath(path), data, getConfig(config))
// }

function post(path, data, config) {
  return api.post(getPath(path), data, getConfig(config))
}

// function update(path, data, config) {
//   return api.put(getPath(path), data, getConfig(config))
// }

export default function(resourceName) {
  return {
    getAll: (params) => get(resourceName, params),
    getOne: (id, params) => get(`${resourceName}/${id}`, params),
    post:   (body) => post(`${resourceName}`, body),
  }
}
