export const footerHeight = '75px'

export const headerHeight = '60px'

export const gaps = {
  l:   '48px',
  m:   '32px',
  s:   '16px',
  xl:  '64px',
  xs:  '8px',
  xxl: '96px',
}
