import { arrayOf, bool, oneOfType, shape, string } from 'prop-types'

export const award = shape({
  logoUrl: string,
  name:    string,
})

export const brandShape = shape({
  _id:     string,
  logoUrl: string,
  name:    string,
})

export const categoryShape = shape({
  _id:     string,
  active:  bool,
  logoUrl: string,
  name:    string,
  visible: bool,
})

export const productShape = shape({
  awards:   arrayOf(oneOfType([string, award])),
  imageUrl: string,
  name:     string,
})

export const slideShape = shape({
  backgroundMobileUrl: string,
  backgroundUrl:       string,
  description:         string,
  subTitle:            string,
  title:               string,
})

export const marketingResourceShape = shape({
  description: string,
  fileUrl:     string,
  name:        string,
})
