import { Platform } from 'react-native'

const ShareMessage = async(message) => {
  if (Platform.OS === 'web') {
    // TODO share web functionality

    return message
  }
}

export default ShareMessage
