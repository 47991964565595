/* Global imports */
import React from 'react'
import { connect } from 'react-redux'
import { func, bool } from 'prop-types'
import styled from 'styled-components'
import { TouchableOpacity } from 'react-native'
import { compose } from 'crocks'

/* Local imports */
import { icons } from 'Assets/images'
import Image from 'Components/Image'
import {
  getToken,
} from 'Reducers/user'

/* Component definition */
const SyncIcon = ({ onClick, synchronized }) => {
  if (synchronized) {
    return (
      <TouchableOpacity onPress={() => onClick()}>
        <SImage
          source={icons.sync}
          mHeight={40}
          mWidth={40}
        />
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity onPress={() => onClick()}>
      <SImage
        source={icons.unSync}
        mHeight={40}
        mWidth={40}
      />
    </TouchableOpacity>
  )
}

/* PropTypes */
SyncIcon.propTypes = {
  onClick:      func.isRequired,
  synchronized: bool,
}
SyncIcon.defaultProps = {
  active: false,
}

/* Local utility functions */

/* Styles */
const SImage = styled(Image)`
  flex: 1;
  height: 60px;
`

const mapStateToProps = (state) => ({
  token: getToken(state),
})

const enhanceComponent = compose(
  connect(mapStateToProps),
)

export default enhanceComponent(SyncIcon)
