/* Global imports */
import React from 'react'
import { arrayOf } from 'prop-types'
import { Dimensions, Platform, FlatList, View } from 'react-native'
import styled from 'styled-components'

/* Local imports */
import {
  productShape,
} from 'Definitions/types'
import { prop } from 'ramda'
import { catalogContent } from 'Definitions/colors'
import Bottle from 'Components/Catalog/Bottle'
import Text from 'Components/Text'
import { gaps } from 'Definitions/measures'
import { isMobileOnly } from 'react-device-detect'

const { width } = Dimensions.get('window')

const MAX_LIMIT_CHAR = width > 320 ? 25 : 10

/* Component definition */
const SearchProductList = ({ products }) => {
  return (
    <FlatList
      numColumns={Platform.OS === 'web' && !isMobileOnly ? 4 : 2}
      data={products}
      keyExtractor={prop('_id')}
      renderItem={({ item }) => renderProduct(item)}
    />
  )
}

/* PropTypes */
SearchProductList.propTypes = {
  products: arrayOf(productShape),
}
SearchProductList.defaultProps = {}

/* Local utility functions */
const renderProduct = (product) => {
  return (
    <ProductWrapper>
      <SBottle
        product={product}
        mHeight={120}
        mWidth={130}
        imageHeight={'100%'}
        resizeMode={'contain'}
        Footer={<SText value={getProductName(product.name)} />}
      />
    </ProductWrapper>
  )
}

const getProductName = (name) => {
  if (name.length > MAX_LIMIT_CHAR && Platform.OS !== 'web') {
    return name.toUpperCase().substring(0, MAX_LIMIT_CHAR) + '...'
  }

  return name.toUpperCase()
}

/* Styles */
const ProductWrapper = styled(View)`
  height: 300px;
  height: ${(Platform.OS === 'web' && !isMobileOnly) ? width / 4 + 'px' : width / 2 + 'px'};
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${catalogContent.border};
  flex: 1;
`

const SBottle = styled(Bottle)`
  height: ${width / 6 + 'px'};
`

const SText = styled(Text)`
  font-weight: bold;
  text-align: center;
  padding: 0 ${gaps.s};
`

export default SearchProductList
