/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { NavLink, withRouter } from 'react-router-dom'

/* Local imports */
import { bottomBar } from 'Definitions/colors'
import { categoryShape } from 'Definitions/types'
import Text from 'Components/Text'

/* Component definition */
const BottomItem = ({ category }) => {
  return (
    <Wrapper
      activeStyle={activeCategoryStyle}
      to={`/catalog/category/${category._id}`}>
      <Text type="display3" style={{ letterSpacing: '1px' }} value={category.name} />
    </Wrapper>
  )
}

/* Local utilities */

/* Local Styled Components */
const Wrapper = styled(NavLink)`
  align-items:     center;
  border-top:      3px solid ${bottomBar.bottomItem};
  font-size:       1.4rem;
  display:         flex;
  justify-content: center;
  height:          100%;
  flex:            1;
  margin-right:    2px;

  :last-child: {
    margin-left: 0;
  }

  :hover: {
    text-decoration: none;
  }
`

const activeCategoryStyle = {
  borderTopColor: bottomBar.activeItem,
}

/* PropTypes */
BottomItem.propTypes = {
  category: categoryShape,
}

export default withRouter(BottomItem)
