import autodux from 'autodux'

export const {
  reducer,
  initial,
  slice,
  actions: {
    setDislcaimer,
  },
  selectors: {
    getAccepted,
  },
} = autodux({
  // No need to implement switching logic -- it's
  // done for you.
  actions: {
    setDislcaimer: (state, isAccepted) => ({ ...state, accepted: isAccepted }),
  },
  // The initial value of your reducer state
  initial: {
    accepted: false,
  },

  // No need to select the state slice -- it's done for you.
  selectors: {
  },

  // the slice of state your reducer controls
  slice: 'disclaimer',
})
