import { put } from 'redux-saga/effects'

import { successList, failureList } from 'Reducers/marketingResources'

import api from 'Services/api'

export function * getMarketingResources({ payload }) {
  try {
    const result = yield api('marketingResources').getAll(payload)

    if (result.ok) {
      yield put(successList(result.data))
    } else {
      yield put(failureList(result))
    }
  } catch (e) {
    yield put(failureList(e))
  }
}
