/* Global imports */
import React from 'react'
import { arrayOf, bool, func } from 'prop-types'
import { View } from 'react-native'
import styled from 'styled-components'

/* Local imports */
import SidePane from '../SidePane'
import { marketingResourceShape } from 'Definitions/types'
import ResourceList from './ResourceList'
import { productDetail } from 'Definitions/colors'
import { isMobileOnly } from 'react-device-detect'

const { resourcesMenu } = productDetail

/* Component definition */
const ResourceMenu = ({ onClick, resources, visible }) => {
  return (
    <SidePane onClickOverlay={onClick} position="right" visible={visible}>
      <Wrapper>
        <ResourceList resources={resources} />
      </Wrapper>
    </SidePane>
  )
}

/* PropTypes */
ResourceMenu.propTypes = {
  onClick:   func.isRequired,
  resources: arrayOf(marketingResourceShape),
  visible:   bool,
}

ResourceMenu.defaultProps = {
  visible: false,
}

/* Local utility functions */

/* Local Styled Components */
const Wrapper = styled(View)`
  background-color: ${resourcesMenu.background};
  height:           100%;
  ${isMobileOnly ? `width: 300px` : `width: 400px`}
`

export default ResourceMenu
