import React from 'react'
import { object } from 'prop-types'
import { Platform, View } from 'react-native'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import Menu from './MainMenu'
import MainLogo from './MainLogo'
import TopBarButtons from './TopBarButtons'
import { topNav } from '../../definitions/colors'
import { headerHeight } from '../../definitions/measures'

const TopBar = ({ history }) => (
  <Wrapper>
    <Menu />
    <MainLogo onClick={goHome(history)} />
    <TopBarButtons />
  </Wrapper>
)

/* Local utility functions */
const goHome = ({ location, push }) => () => {
  if (Platform.OS === 'web' && location.pathname !== '/') {
    push('/')
  }
}

TopBar.propTypes = {
  history: object,
}

const Wrapper = styled(View)`
  background-color: ${topNav.background};
  display:          flex;
  flex-direction:   row;
  flex:             0 1 ${headerHeight};
  justify-content:  space-between;
  z-index: 1;
`

export default withRouter(TopBar)
