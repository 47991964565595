import autodux from 'autodux'

export const {
  reducer,
  initial,
  slice,
  actions: {
    failureLogin,
    requestLogin,
    requestLogout,
    successLogin,
  },
  selectors: {
    getToken,
    getFetching,
    getError,
  },
} = autodux({
  // No need to implement switching logic -- it's
  // done for you.
  actions: {
    failureLogin:  (state) => ({ ...state, fetching: false, error: true }),
    requestLogin:  (state) => ({ ...state, fetching: true, error: false }),
    requestLogout: (state) => ({ ...state, fetching: false, error: false, token: null }),
    successLogin:  (state, token) => ({ ...state, fetching: false, token, error: false }),
  },

  // The initial value of your reducer state
  initial: {
    error:    false,
    fetching: false,
    token:    null,
  },

  // No need to select the state slice -- it's done for you.
  selectors: {
  },

  // the slice of state your reducer controls
  slice: 'user',
})
