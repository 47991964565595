/* Global imports */
import React, { useEffect, useState } from 'react'
import { arrayOf, bool, func, object, string } from 'prop-types'
import { Platform } from 'react-native'
import { connect } from 'react-redux'

/* Local imports */
import { getToken } from 'Reducers/user'
import {
  getList,
  getError as getMarketingResourcesError,
  getFetching as getMarketingResourcesFetching,
  requestList,
} from 'Reducers/marketingResources'
import Search from 'Components/Search'
import ResourceList from 'Components/ProductDetail/ResourceList'
import { marketingResourceShape } from 'Definitions/types'

/* Component definition */
const MarketingResourcesScreen = ({
  error,
  fetching,
  getResources,
  navigation,
  resources,
}) => {
  const [ searchText, setSearchText ] = useState('')

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const listener = navigation.addListener('willFocus', () => {
        getResources({ search: searchText })
      })

      return () => { listener.remove() }
    }
  }, [])

  useEffect(() => {
    getResources({ search: searchText })
  }, [searchText])

  return (
    <>
      <Search onSearch={(text) => setSearchText(text)} />
      <ResourceList
        onRefresh={() => getResources({ search: searchText })}
        resources={resources}
        fetching={fetching}
        error={error}
      />
    </>
  )
}

/* PropTypes */
MarketingResourcesScreen.propTypes = {
  error:        bool,
  fetching:     bool,
  getResources: func.isRequired,
  navigation:   object,
  resources:    arrayOf(marketingResourceShape),
  token:        string,
}

MarketingResourcesScreen.defaultProps = {}

/* Local utility functions */

/* Styles */

const mapStateToProps = state => ({
  error:     getMarketingResourcesError(state),
  fetching:  getMarketingResourcesFetching(state),
  resources: getList(state),
  token:     getToken(state),
})

const mapDispatchToProps = dispatch => ({
  getResources: (params) => dispatch(requestList(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarketingResourcesScreen)
