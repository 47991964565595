/* Global imports */
import { compose } from 'crocks'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Local imports */
import DisclaimerScreen from './DisclaimerScreen'
import {
  setDislcaimer,
  getAccepted,
} from 'Reducers/disclaimer'

const mapStateToProps = (state) => ({
  isDisclaimerAccepted: getAccepted(state),
})

const mapDispatchToProps = (dispatch) => ({
  acceptDisclaimer: () => dispatch(setDislcaimer(true)),
})

const enhanceComponent = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)

export default enhanceComponent(DisclaimerScreen)
