/* Global imports */
import React from 'react'
import { Linking, TouchableOpacity, View } from 'react-native'
import {
  isAndroid,
} from 'react-device-detect'
import styled from 'styled-components'

/* Local imports */
import { blueLogo } from 'Assets/images'
import { gaps } from 'Definitions/measures'
import { forceDownloadApp } from 'Definitions/colors'
import Image from 'Components/Image'
import Text from 'Components/Text'

/* Component definition */
const ForceDownloadApp = () => {
  return (
    <Wrapper>
      <Logo
        source={blueLogo}
        resizeMode="contain"
      />
      <TextWrapper>
        <Text
          value={'forceDownloadApp.contentText'}
          translate
        />
      </TextWrapper>

      { renderButton() }
    </Wrapper>
  )
}

/* PropTypes */
ForceDownloadApp.propTypes = {}
ForceDownloadApp.defaultProps = {}

/* Local utility functions */
const renderButton = () => {
  // TODO: link to the stores

  if (isAndroid) {
    return (
      <AppButton onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.caballero.catalog&hl=es_419')} >
        <AppText
          value={'forceDownloadApp.buttons.androidText'}
          translate
        />
      </AppButton>
    )
  }

  return (
    <View>
      <AppButton onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.caballero.catalog&hl=es_419')} >
        <AppText
          value={'forceDownloadApp.buttons.androidText'}
          translate
        />
      </AppButton>
    </View>
  )
}
/* Styles */

const Wrapper = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 ${gaps.m};
`

const Logo = styled(Image)`
  height: 100px;
`

const TextWrapper = styled(View)`
  padding-top: ${gaps.m};
  padding-bottom: ${gaps.m};
  text-align: center;
`

const AppButton = styled(TouchableOpacity)`
  background-color: ${forceDownloadApp.background};
  border-radius: 5px;
  margin-top: ${gaps.s}
`

const AppText = styled(Text)`
  color: ${forceDownloadApp.text};
  padding: ${gaps.xs} ${gaps.m};
`

export default ForceDownloadApp
