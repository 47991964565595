/* Global imports */
import React from 'react'
import { Dimensions, FlatList, View } from 'react-native'
import { arrayOf } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'ramda'

/* Local imports */
import { productShape } from 'Definitions/types'
import Bottle from './BottleMobile'
import BottleFooter from 'Components/Catalog/BottleFooter'

const { height, width } = Dimensions.get('window')

/* Component definition */
const BottleSliderList = ({ products }) => {
  return (
    <BottleList>
      <FlatList
        bounces={false}
        data={products}
        keyExtractor={prop('_id')}
        renderItem={({ item }) =>
          <SBottle
            product={item}
            mHeight={height / 1.5}
            mWidth={width - 50}
            Footer={<BottleFooter title={item.name} />}
            resizeMode="contain"
          />
        }
        horizontal={true}
      />
    </BottleList>
  )
}

/* PropTypes */
BottleSliderList.propTypes = {
  products: arrayOf(productShape),
}
BottleSliderList.defaultProps = {}

/* Local utility functions */

/* Styles */
const BottleList = styled(View)`
  align-items:    flex-end;
  display:        flex;
  flex-direction: row;
  flex:           1 1 auto;
`

const SBottle = styled(Bottle)`
  align-self: flex-end;
`

export default BottleSliderList
