/* Global imports */
import { compose } from 'crocks'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Local imports */
import LoginScreen from './LoginScreen'
import { getToken } from 'Reducers/user'

const mapStateToProps = state => ({
  token: getToken(state),
})

const enhanceComponent = compose(
  withRouter,
  connect(mapStateToProps),
)

export default enhanceComponent(LoginScreen)
