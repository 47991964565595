/* Global imports */
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { bool, element, func, oneOf } from 'prop-types'

/* Local imports */

const overlayRef = React.createRef()

/* Component definition */
const SidePane = ({ children, onClickOverlay, position, visible }) => (
  <>
    <Overlay
      activeOpacity={1}
      ref={overlayRef}
      visible={visible}
      onPress={onClickOverlay}
    />
    <Wrapper visible={visible} position={position}>
      { children }
    </Wrapper>
  </>
)

/* PropTypes */
SidePane.propTypes = {
  children:       element,
  onClickOverlay: func,
  position:       oneOf(['left', 'right']),
  visible:        bool,
}

SidePane.defaultProps = {
  position: 'left',
  visible:  false,
}

/* Local Styled Components */
const Wrapper = styled(View)`
  ${p => p.position}: 0;
  height:             100%;
  max-width:          ${p => p.visible ? '1000px' : 0};
  opacity:            0.95;
  overflow:           hidden;
  position:           absolute;
  top:                0;
  transition:         max-width 0.5s;
  z-index:            1;
`

// const getTransform = p => p.visible ? 'none' : 'rotate3d(0, 1, 0, 90deg)'
const getTransform = p => p.visible ? 'none' : 'rotate3d(0, 1, 0, 90deg)'

// TODO: set overlay background:
const Overlay = styled(TouchableOpacity)`
  background: rgba(0, 0, 0, 0.3) none repeat scroll 0% 0%;
  height:     100%;
  opacity:    ${p => p.visible ? 1 : 0};
  position:   absolute;
  transform:  ${getTransform};
  transition: opacity 0.3s ease 0s;
  width:      100%;
  z-index:    1;
`

export default SidePane
