/* Global imports */
import React from 'react'
import { Platform, TextInput, View } from 'react-native'
import { func } from 'prop-types'
import styled from 'styled-components'
import I18n from 'i18n-js'

/* Local imports */
import Image from 'Components/Image'
import { icons } from 'Assets/images'
import { catalogContent } from 'Definitions/colors'

/* Component definition */
const Search = ({ onSearch }) => {
  return (
    <Wrapper>
      <STextInput
        placeholder={I18n.t('search')}
        onChangeText={text => onSearch(text)}
      />
      <SearchIcon
        source={icons.searchBlue}
        mHeight={60}
        mWidth={60}
      />
    </Wrapper>
  )
}

/* PropTypes */
Search.propTypes = {
  onSearch: func.isRequired,
}
Search.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`
  flex-direction: row;
  border-bottom-color: ${catalogContent.selected};
  border-bottom-width: 4px;
  justify-content: space-between;
  background-color: white;
  z-index: 1;
  height: 100px;
  align-items: center;
`

const STextInput = styled(TextInput)`
  padding-left: 10px;
  flex: 1;
  font-size: 24px;
  ${Platform.OS === 'web' ? 'outline: none' : 'none'};
  color: ${catalogContent.text};
  font-weight: 300;
`

const SearchIcon = styled(Image)`
  height: 60px;
`

export default Search
