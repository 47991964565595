/* Global imports */
import React, { useEffect } from 'react'
import { arrayOf, func, string } from 'prop-types'
import { FlatList, Platform, View } from 'react-native'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { prop } from 'ramda'
import { Link } from 'react-router-dom'

/* Local imports */
import { brandShape } from 'Definitions/types'
import {
  requestList as requestBrandList,
  getList as getBrandList,
} from 'Reducers/brands'
import BrandItem from './BrandItem'

/* Component definition */
const BrandSlider = ({ brands, categorySelected, getBrands }) => {
  useEffect(() => { getBrands(categorySelected) }, [categorySelected])

  return (
    <Wrapper>
      <SFlatList
        data={brands}
        keyExtractor={prop('_id')}
        renderItem={({ item }) => renderItem(item, categorySelected)}
        horizontal={true}
      />
    </Wrapper>
  )
}

/* PropTypes */
BrandSlider.propTypes = {
  brands:           arrayOf(brandShape),
  categorySelected: string,
  getBrands:        func.isRequired,
}

BrandSlider.defaultProps = {
  brands: [],
}

/* Local utility functions */

const renderItem = (item, categorySelected) => {
  const onClickBrand = () => void 0

  if (Platform.OS === 'web') {
    return (
      <SLink to={`/catalog/category/${categorySelected}/brand/${item._id}`}>
        <BrandItem
          brand={item}
          onPress={onClickBrand}
        />
      </SLink>
    )
  }

  return (
    <BrandItem
      brand={item}
      onPress={onClickBrand}
    />
  )
}

/* Styles */
const Wrapper = styled(View)`
  align-items:    center;
  display:        flex;
  flex-direction: row;
  padding:        20px 0;
`

const SFlatList = styled(FlatList)`
  ${Platform.OS === 'web' ? 'justify-content: center' : null}
`

const SLink = styled(Link)`
  padding: 0 21px;
  align-self: center;
`

const mapStateToProps = (state) => ({
  brands: getBrandList(state),
})

const mapDispatchToProps = (dispatch) => ({
  getBrands: (category) => dispatch(requestBrandList({ category })),
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandSlider)
