/* Global imports */
import React, { useEffect } from 'react'
import {
  arrayOf,
  bool,
  func,
} from 'prop-types'
import { ActivityIndicator, View } from 'react-native'
import { connect } from 'react-redux'
import { anyPass } from 'ramda'
import styled from 'styled-components'

/* Local imports */
import API from 'Services/api'
import { catalogContent } from 'Definitions/colors'
import ErrorMessage from 'Components/ErrorMessage'
import Text from 'Components/Text'

import {
  categoryShape,
  productShape,
  brandShape,
} from 'Definitions/types'

/* Sync */
import {
  setSync,
} from 'Reducers/sync'

/* Products */
import {
  getList as getProductsList,
  getError as getProductsError,
  getFetching as getProductsFetching,
  requestList as requestProductList,
} from 'Reducers/products'

/* Brands */
import {
  getList as getBrandList,
  getError as getBrandsError,
  getFetching as getBrandsFetching,
  requestList as requestBrandList,
} from 'Reducers/brands'

/* Categories */
import {
  getList as getCategoriesList,
  getError as getCategoriesError,
  getFetching as getCategoriesFetching,
  requestList as requestCategoriesList,
} from 'Reducers/categories'

/* Marketing Resources */
import {
  getList as getMarketingResourcesList,
  getError as getMarketingResourcesError,
  getFetching as getMarketingResourcesFetching,
  requestList as requestMarketingResourcesList,
} from 'Reducers/marketingResources'

/* Slides */
import {
  request as requestSlides,
  getError as getSlidesError,
  getFetching as getSlidesFetching,
  getSlides as getSlidesList,
} from 'Reducers/sliders'

const apiDocuments = API('documents')

/* Component definition */
const SyncScreen = ({
  categories,
  error,
  fetching,
  getBrands,
  getCategories,
  getMarketingResources,
  getProducts,
  getSlides,
  setSynchronized,
}) => {
  useEffect(() => {
    setSynchronized(true)
    getCategories()
    getMarketingResources()
    getSlides({
      callback: (slides) => getSlidesImages(slides),
    })
  }, [])

  useEffect(() => {
    if (categories && categories.length > 0) {
      categories.forEach((category) => {
        getBrands({
          callback: (brands) => {
            getBrandsImages(brands)
            brands.forEach(({ _id: brand }) => {
              getProducts({ brand })
            })
          },
          category: category._id,
        })
        getProducts({
          callback: (products) => getProductsImages(products),
          category: category._id,
        })
      })
    }
  }, [categories])

  useEffect(() => {
    if (error) {
      setSynchronized(false)
    }
  }, [error])

  if (fetching) {
    return (
      <MessageWrapper>
        <ActivityIndicator size="large" color={catalogContent.spinner} />
      </MessageWrapper>
    )
  }

  if (error) {
    return (
      <MessageWrapper>
        <ErrorMessage />
      </MessageWrapper>
    )
  }

  return (
    <MessageWrapper>
      <Text value="syncScreen.synchronized" translate />
    </MessageWrapper>
  )
}

/* PropTypes */
SyncScreen.propTypes = {
  brands:                arrayOf(brandShape),
  brandsError:           bool,
  brandsFetching:        bool,
  categories:            arrayOf(categoryShape),
  categoriesError:       bool,
  categoriesFetching:    bool,
  error:                 bool,
  fetching:              bool,
  getBrands:             func.isRequired,
  getCategories:         func.isRequired,
  getMarketingResources: func.isRequired,
  getProducts:           func.isRequired,
  getSlides:             func.isRequired,
  products:              arrayOf(productShape),
  productsError:         bool,
  productsFetching:      bool,
  setSynchronized:       func.isRequired,
}
SyncScreen.defaultProps = {}

/* Local utility functions */
const getSlidesImages = (slides) => {
  slides.forEach(slide => {
    apiDocuments.getOne(slide.background + '/file')
  })
}

const getProductsImages = (products) => {
  products.forEach(product => {
    apiDocuments.getOne(product.image + '/file')

    product.awards.forEach(award => {
      apiDocuments.getOne(award.logo + '/file')
    })
  })
}

const getBrandsImages = (brands) => {
  brands.forEach(brand => {
    apiDocuments.getOne(brand.logo + '/file')
  })
}

/* Styles */
const MessageWrapper = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
`

const mapStateToProps = (state) => {
  const isFetching = anyPass([
    getBrandsFetching,
    getMarketingResourcesFetching,
    getProductsFetching,
    getProductsFetching,
    getSlidesFetching,
  ])

  const isError = anyPass([
    getBrandsError,
    getMarketingResourcesError,
    getProductsError,
    getProductsError,
    getSlidesError,
  ])

  return {
    brands:         getBrandList(state),
    brandsError:    getBrandsError(state),
    brandsFetching: getBrandsFetching(state),

    categories:         getCategoriesList(state),
    categoriesError:    getCategoriesError(state),
    categoriesFetching: getCategoriesFetching(state),

    error:    isError(state),
    fetching: isFetching(state),

    marketingResourcesError:    getMarketingResourcesError(state),
    marketingResourcesFetching: getMarketingResourcesFetching(state),
    marketingResourcess:        getMarketingResourcesList(state),

    products:         getProductsList(state),
    productsError:    getProductsError(state),
    productsFetching: getProductsFetching(state),

    slides:         getSlidesList(state),
    slidesError:    getSlidesError(state),
    slidesFetching: getSlidesFetching(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBrands:             (params) => dispatch(requestBrandList(params)),
  getCategories:         (params) => dispatch(requestCategoriesList(params)),
  getMarketingResources: () => dispatch(requestMarketingResourcesList()),
  getProducts:           (params) => dispatch(requestProductList(params)),
  getSlides:             (params) => dispatch(requestSlides(params)),
  setSynchronized:       (bool) => dispatch(setSync(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SyncScreen)
