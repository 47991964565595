/* Global imports */
import Modal from 'modal-react-native-web'

/* Local imports */
import DisclaimerModal from './DisclaimerModal'

// FIXME
export { Modal }

export default DisclaimerModal
