import React from 'react'

import { View } from 'react-native'
import { productDetail as colors } from 'Definitions/colors'
import { isEmpty } from 'crocks/predicates'

import { concat, defaultTo, map, pipe, reject, toPairs } from 'ramda'
import ProductTable from 'Components/Catalog/ProductTable'
import Awards from './Awards'
import Text from 'Components/Text'

const getKey = pipe(
  defaultTo(''),
  concat('productDetail.tables.values.')
)

const getData = map(({ key, value }) => [getKey(key), value])

export const renderDataTables = (token, data, title, prefix) => {
  const toKeyValueObjs = pipe(
    toPairs,
    reject(([key]) => ['_id', 'id'].includes(key)),
    map(([key, value]) => ({ key, value })),
    map(({ key, value }) => ({ key: `${prefix}.${key}`, value })),
  )

  if (!token || data.length === 0) {
    return null
  }

  const result = map((table) => (
    <ProductTable
      key={title + Math.round(Math.random() * 100)}
      title={title}
      data={getData(toKeyValueObjs(table))}
    />
  ), data)

  return result
}

export const renderTable = (token, data, title, prefix) => {
  if (token && data.length > 0) {
    const addPrefixes = map(({ key, value }) => ({ key: `${prefix}.${key}`, value }))

    return (
      <ProductTable
        title={title}
        data={getData(addPrefixes(data))}
      />
    )
  }

  return null
}

// Award :: { name :: string, logo :: string }

// renderAwards :: [ Award ] -> [ <Award> ]
export const renderAwards =
      (awards) =>
        awards && awards.length > 0 ? <Awards awards={awards} /> : null

export const renderProductValue =
  (value, translateString, gap = 's') => {
    if (value) {
      return (
        <>
          <Text
            color={colors.text}
            type="headline"
            gap={gap}
            value={`productDetail.${translateString}`}
            translate
          />
          <View>
            { notEmpty(value) && <Text color={value.text} gap="m" value={value} /> }
          </View>
        </>
      )
    }

    return null
  }

export const notEmpty = string => !isEmpty(string)
