/* Global imports */
import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { arrayOf, func, string } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import Text from 'Components/Text'
import Image from 'Components/Image'
import { brandShape, categoryShape } from 'Definitions/types'
import { catalogBrandSelector } from 'Definitions/colors'
import { icons } from 'Assets/images'

/* Component definition */
const BrandSelectorButtons = ({ isActive, subCategories, onClick }) => {
  return (
    <View>
      <ButtonsWrapper>
        <BrandWrapper onPress={() => onClick('brand')}>
          <BrandIconWrapper isActive={isActive}>
            <Image
              source={icons.arrow}
              mHeight={40}
              mWidth={40}
              maxWidth
            />
          </BrandIconWrapper>
          <Text value="catalog.brands" translate />
        </BrandWrapper>
        { subCategories.length > 0 && <SubcategoryWrapper onPress={() => onClick('subCategory')}>
          <Text value="catalog.subCategories" translate />
          <SubCategoriesIconWrapper isActive={isActive}>
            <Image
              source={icons.arrow}
              mHeight={40}
              mWidth={40}
              maxWidth
            />
          </SubCategoriesIconWrapper>
        </SubcategoryWrapper> }
      </ButtonsWrapper>
    </View>
  )
}

/* PropTypes */
BrandSelectorButtons.propTypes = {
  brands:        arrayOf(brandShape),
  categories:    arrayOf(categoryShape),
  isActive:      string,
  onClick:       func.isRequired,
  subCategories: arrayOf(categoryShape),
}
BrandSelectorButtons.defaultProps = {}

/* Local utility functions */

/* Styles */
const ButtonsWrapper = styled(View)`
  border-top-color: ${catalogBrandSelector.border};
  border-top-width: 2px;
  flex-direction: row;
  height: 60px;
`

const BrandWrapper = styled(TouchableOpacity)`
  align-items: center;
  flex-direction: row;
  flex: 1;
  padding: 0 10px;
`

const SubcategoryWrapper = styled(TouchableOpacity)`
  flex: 1;
  padding: 0 10px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const BrandIconWrapper = styled(View)`
  transform: ${p => p.isActive === 'brand' ? 'rotate(180deg)' : 'rotate(0deg)'};
  max-width:60px;
`

const SubCategoriesIconWrapper = styled(View)`
  transform: ${p => p.isActive === 'subCategory' ? 'rotate(180deg)' : 'rotate(0deg)'};
  max-width:60px;
`

export default BrandSelectorButtons
