/* Global imports */
import React from 'react'
import { bool, func } from 'prop-types'
import {
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native'
import styled from 'styled-components'

/* Local imports */
import { Modal } from '.'
import { blueLogo } from 'Assets/images'
import Image from 'Components/Image'
import Text from 'Components/Text'
import { gaps } from 'Definitions/measures'
import { disclaimer as disclaimerColors } from 'Definitions/colors'

/* Component definition */
const DisclaimerModal = ({ visible, onClick }) => {
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={visible}>
      <Wrapper>
        <Content>
          <Logo>
            <LogoImage source={blueLogo} resizeMode="contain" />
          </Logo>
          <TextWrapper>

            <MainTitle type="display1" value="Aviso Legal" />

            <H5 value="1. Titularidad" type="display3" />

            <Text value="https://app.caballero.es/ es un dominio en Internet propiedad de LUÍS CABALLERO COMERCIAL Y DISTRIBUCIÓN S.A. con NIF: A79208526 con domicilio en C/ Juan de Mena, 10. 28014. Madrid. Registro Mercantil de Madrid, Tomo 6.554, Hoja M-106665, Folio 69, Sección 8ª, Libro 0, Inscripcción 7ª (en adelante el Titular)." />

            <Text value="Grupo Caballero es un grupo empresarial, con origen en El Puerto de Santa María, que desarrolla principalmente actividades de producción de vinos y bebidas espirituosas." />

            <H5 value="2. Condiciones Generales de Usos" type="display3"></H5>

            <Text value="El presente Sitio Web está diseñado para ser utilizado únicamente por personas que sean legalmente mayores de edad para comprar alcohol en el país en el que residan o bien en el país desde el que se está accediendo al Sitio Web. Si el usuario es legalmente menor de edad, estaría incumpliendo las leyes o regulaciones aplicables en su país de residencia o en el país desde el que accede, por lo tanto, deberá abandonar el Sitio de inmediato." />

            <Text value="Al acceder al Sitio Web el Usuario declara que acepta el presente Aviso Legal con todas sus condiciones de uso. En todo caso, este Aviso Legal tiene carácter obligatorio y vinculante; cualquier persona que no acepte sus condiciones deberá abstenerse de utilizar el Sitio Web y/o los servicios promocionados por el Titular, a través del mismo. Estas condiciones no crean ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre el Titular y los Usuarios." />

            <Text value="El presente Aviso Legal regula el uso de este Sitio Web, el cual pone el Titular a disposición de las personas que accedan con el fin de proporcionarles información sobre productos propios y/o de terceros colaboradores, y facilitarles el acceso de los mismos." />

            <H5 value="3. Normas aplicables" type="display3" />

            <Text value="El presente Aviso Legal está sujeto a lo dispuesto en la Ley Orgánica 3/2018, de Protección de Datos Personales y garantía de los derechos digitales, de 5 de diciembre de 2018 (LOPDGDD), Reglamento UE 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento General de Protección de Datos), la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, el Real Decreto-ley 13/2012, de 30 de marzo, por el que se transponen directivas en materia de mercados interiores de electricidad y gas y en materia de comunicaciones electrónicas, y por el que se adoptan medidas para la corrección de las desviaciones por desajustes entre los costes e ingresos de los sectores eléctrico y gasista, así como cualquier normativa posterior que las modifican o desarrollan." />

            <Text value="Tanto el acceso al Sitio Web propiedad del Titular como el uso que pueda hacerse de la información y contenidos incluidos en el mismo, será de la exclusiva responsabilidad de quien lo realice. Las condiciones de acceso al Sitio Web estarán supeditadas a la legalidad vigente y los principios de la buena fe y uso lícito por parte del Usuario de la misma, quedando prohibido con carácter general cualquier tipo de actuación en perjuicio del Titular. Se considerará terminantemente prohibido el uso del Sitio Web con fines ilegales o no autorizados." />

            <H5 value="4. Modificación de las condiciones de uso" type="display3" />

            <Text value="El Titular se reserva el derecho de modificar, en cualquier momento, la presentación y configuración del Sitio Web, así como el presente Aviso Legal. Por ello, El Titular recomienda al Usuario leerlas atentamente cada vez que acceda al Sitio Web. Siempre dispondrá del Aviso Legal en un sitio visible, libremente accesible para cuantas consultas quiera realizar." />

            <H5 value="5. Descripción de los servicios" type="display3" />

            <Text value="https://app.caballero.es/ es un Sitio Web propiedad del Titular y que sirve de herramienta tanto de información como de contratación de los servicios ofrecidos." />

            <H5 value="6. Registro" type="display3" />

            <Text value="No es necesario realizar un registro. Existe un apartado denominado “área comercial” para usuario comercial del Grupo así como para usuario de acceso libre." />
            <Text value="El identificador de usuario comercial del Grupo está dado de alta con su usuario y contraseña. La contraseña deberá estar compuesta por al menos 5 caracteres. Respecto al usuario de libre acceso, podrá acceder al catálogo una vez se les facilite previamente el enlace o el alojamiento para descargarlo tanto desde Apple Store como de Play Store o de Microsoft Store." />

            <Text value="El uso de su identificador y la contraseña son personales e intransferibles, no estando permitida la cesión, ni siquiera temporal, a terceros. En tal sentido, el Usuario se compromete a hacer un uso diligente y a mantener en secreto los mismos, asumiendo toda responsabilidad por las consecuencias de su divulgación a terceros. En el supuesto de que un usuario conozca o sospeche del uso de su contraseña por terceros, deberá notificárnoslo de forma inmediata." />
            <Text value="El Titular podrá interrumpir el servicio a cualquier Usuario que haga una utilización no ética, ofensiva, ilegal, o incorrecta de los contenidos o servicios de este Sitio Web y/o contraria a los intereses del Titular. Así también se reserva el derecho de rechazar cualquier solicitud de registro o cancelar un registro previamente aceptado, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento." />

            <H5 value="7. Terminación" type="display3" />

            <Text value="El Titular se reserva el derecho de interrumpir o cancelar el Sitio Web o cualquiera de los servicios en el mismo, en cualquier momento y sin previo aviso, por motivos técnicos o de cualquier otra índole, pudiendo así mismo modificar unilateralmente tanto las condiciones de acceso, como la totalidad o parte de los contenidos en él incluidos todo ello sin perjuicio de los derechos adquiridos en dicho momento." />

            <H5 value="8.  Responsabilidades" type="display3" />

            <Text value="El Titular no asume ninguna responsabilidad por el contenido, informaciones o servicios que pudieran aparecer en dichos sitios, que tendrán exclusivamente carácter informativo y que en ningún caso implican relación alguna entre el Titular y las personas o entidades titulares de tales contenidos o titulares de los sitios donde se encuentren." />

            <Text value="El Titular no se hace responsable bajo ningún concepto por ningún tipo de daño que pudiesen ocasionar los Usuarios al presente Sitio Web, o a cualquier otra, por el uso ilegal o indebido de la misma, o de los contenidos e informaciones accesibles o facilitadas a través de ella." />

            <Text value="El Titular no será responsable de las infracciones realizadas por usuarios de su Web que afecten a terceros." />

            <Text value="El Titular no garantiza la fiabilidad, la disponibilidad ni la continuidad de su Sitio Web ni de los Servicios, por lo que la utilización de los mismos por parte del usuario se lleva a cabo por su propia cuenta y riesgo, sin que, en ningún momento, puedan exigirse responsabilidades por ello al Titular." />

            <Text value="El Titular no será responsable en caso de que existan interrupciones de los servicios, demoras, mal funcionamiento del mismo y, en general, demás inconvenientes que tengan su origen en causas que escapan del control del Titular, y/o debida a una actuación dolosa o culposa del usuario y/o tenga por origen causas de caso fortuito o fuerza mayor. Sin perjuicio de lo establecido en el artículo 1105 del Código Civil, se entenderán incluidos en el concepto de fuerza mayor, además, y a los efectos del presente Aviso Legal, todos aquellos acontecimientos acaecidos fuera del control del Titular, tales como: fallo de terceros, operadores o compañías de servicios, actos de Gobierno, falta de acceso a redes de terceros, actos u omisiones de las Autoridades Públicas, aquellos otros producidos como consecuencia de fenómenos naturales, cortes de suministro, etc. y el ataque de hackers o terceros especializados en la seguridad o integridad del sistema informático, siempre que el Titular, haya adoptado las medidas de seguridad razonables de acuerdo con el estado de la técnica. En cualquier caso, sea cual fuere su causa, el Titular no asumirá responsabilidad alguna ya sea por daños directos o indirectos, daño emergente y/o por lucro cesante." />

            <Text value="El Titular tratará en la medida de lo posible de actualizar y rectificar aquella información alojada en su Sitio Web que no cumpla con las mínimas garantías de veracidad. No obstante, informa que puede existir cualquier tipo de error sin intencionalidad y siendo el Usuario libre de contrastar el contenido." />

            <Text value="El Titular no podrá ser considerado responsable del uso del presente Sitio Web por personas ajenas o referencias que pudieran existir en sitios ajenos." />

            <H5 value="9. Indemnización" type="display3" />

            <Text value="Los Usuarios mantendrán al Titular indemne por cualquier reclamación o demanda de terceros relacionada con las actividades promovidas dentro del Sitio Web o por el incumplimiento del Aviso Legal y demás políticas que se entienden incorporadas al presente documento, o por la violación de cualesquiera leyes o derechos de terceros." />

            <H5 value="10. Nulidad e ineficacia de las Cláusulas" type="display3" />

            <Text value="Si cualquier cláusula incluida en este Aviso Legal o del resto de texto legales e informativos del Sitio Web fuese declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o a la parte de la misma que resulte nula o ineficaz, subsistiendo el resto de condiciones establecidas en todo lo demás, teniéndose tal disposición, o la parte de la misma que resultase afectada, por no incluida." />

            <H5 value="11. Notificaciones" type="display3" />

            <Text value="Todas las notificaciones, requerimientos, peticiones y otras comunicaciones que hayan de efectuarse por las partes en relación con el presente Aviso Legal o resto de textos legales e informativos, deberán realizarse por escrito y se entenderá que han sido debidamente realizadas cuando hayan sido entregadas en mano o bien remitidas por correo ordinario al domicilio de la otra parte o al correo electrónico de ésta, o bien a cualquier otro domicilio o correo electrónico que a estos efectos cada parte pueda indicar a la otra." />

            <H5 value="12. Propiedad Intelectual e Industrial" type="display3" />

            <Text value="Los derechos de propiedad intelectual de este Sitio Web, su código fuente, diseño, estructuras de navegación y los distintos elementos en ella contenidos son titularidad exclusiva del Titular a quien corresponde el ejercicio de los derechos de explotación de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación, de acuerdo con la legislación española y de la Unión Europea aplicable." />

            <Text value="Queda totalmente prohibida la reproducción total o parcial de los contenidos de este Sitio Web sin consentimiento expreso y por escrito por parte del Titular. La utilización no autorizada de estos contenidos por cualquier otra persona o empresa dará lugar a las responsabilidades legalmente establecidas." />

            <Text value="El Titular comercializará los productos con las Marcas Registradas y con los distintivos comerciales que sus proveedores indiquen. Éstos son los propietarios legítimos de las Marcas Registradas y del material promocional que puedan poner a disposición para la promoción del producto y conceden al Titular la autorización necesaria para el uso de los mismos sin considerarse en ningún caso una cesión de su propiedad, siendo el proveedor el responsable exclusivo de cualquier demanda interpuesta por terceros en relación al uso de la Marca Comercial, eximiendo al Titular cualquier responsabilidad sobre los derechos de propiedad intelectual." />

            <Text value="Queda prohibida cualquier modalidad de explotación, incluyendo todo tipo de reproducción, distribución, cesión a terceros, comunicación pública y transformación, mediante cualquier tipo de soporte y medio, de las obras antes referidas, creaciones y signos distintivos sin autorización previa y expresa de sus respectivos titulares. El incumplimiento de esta prohibición podrá constituir infracción sancionable por la legislación vigente." />

            <Text value="Queda prohibido, salvo en los casos que expresamente lo autorice el Titular presentar este Sitio Web o la información contenida en ella bajo frames o marcos, signos distintivos, marcas o denominaciones sociales o comerciales de otra persona, empresa o entidad incluyendo de forma expresa el contenido fotográfico que se considera propiedad exclusiva del Titular." />

            <Text value="La infracción de cualquiera de los citados derechos puede constituir una vulneración de las presentes condiciones, así como un delito castigado de acuerdo con los artículos 270 y siguientes del Código Penal." />

            <Text value="El Titular no se hace responsable por la utilización que el Usuario realice de los Servicios del Sitio Web, así como de cualquier material que él mismo incluya en el presente Sitio Web, que puedan infringir los derechos de propiedad intelectual o industrial o cualquier otro derecho de terceros." />

            <H5 value="13. Jurisdicción" type="display3" />

            <Text value="Para cuantas cuestiones se susciten sobre la interpretación, aplicación y cumplimiento de este Aviso Legal, así como de las reclamaciones que puedan derivarse de su uso, todas las partes intervinientes se someten a los Jueces y Tribunales que correspondan según su fuero." />

            <H4 value="Condiciones Generales de Compra" type="display3" />

            <Text value="Este Sitio Web no dispone de compra online directa. Existe un catálogo de productos pero no se pueden formalizar pedidos online." />

            <H4 value="Política de privacidad." type="display3" />

            <Text value="El Titular se compromete a cumplir con la normativa en materia de Protección de Datos de carácter personal y a respetar la privacidad de los Usuarios. El objetivo es ofrecer el mejor servicio al Usuario y para ello es necesario contar con sus datos." />

            <H5 value="1. Responsable del Tratamiento" type="display3" />

            <Text value="LUÍS CABALLERO COMERCIAL Y DISTRIBUCIÓN S.A." />

            <Text value="NIF: A79208526" />

            <Text value="Domicilio: C/ Juan de Mena, 10. 28014. Madrid" />

            <Text value="Teléfono: 956 85 17 51" />

            <H5 value="2. Finalidades" type="display3" />

            <Text value="Comunicaciones comerciales: Para el Usuario que voluntariamente acepte esta opción, se le remitirán promociones, publicidad, ofertas, noticias, invitación a eventos y cualquier tipo de información comercial del Titular." />

            <Text value="Comunicaciones comerciales personalizadas: Para el Usuario que voluntariamente acepte esta opción, se le remitirán promociones, publicidad, ofertas, noticias, invitación a eventos y cualquier tipo de información comercial personalizada que el Titular pueda determinar en función de su comportamiento, preferencias y perfil personal." />

            <H5 value="3. Legitimación y conservación" type="display3" />

            <Text value="Base jurídica del tratamiento: Consentimiento otorgado por el Usuario facilitando sus datos de forma voluntaria y aceptando la Política de Privacidad." />

            <Text value="En caso de no facilitar los datos necesarios para estas finalidades no será posible prestarle los servicios. Los datos se conservarán mientras se mantenga le relación y no se solicite su supresión y en cualquier caso en cumplimiento de plazos legales de prescripción que le resulten de aplicación." />

            <H5 value="4. Cesiones" type="display3" />

            <Text value="Ceden datos al resto de empresas que forman el “Grupo Caballero”: LUÍS CABALLERO S.A.; EMILIO LUSTAU S.A.; BODEGAS VIÑA HERMINIA S.L.; CABALLERO EL PUERTO S.L.U.; BODEGAS CABALLERO S.L.U.; PUERTO FINO S.A. y MANUEL CUEVAS JURADO S.L." />

            <H5 value="5. Derechos de los interesados" type="display3" />

            <Text value="Los Usuarios pueden ejercitar sus derechos de acceso, rectificación, supresión, portabilidad y la limitación u oposición enviando solicitud firmada por correo postal con asunto “Protección de Datos”, a la dirección: C/ San Francisco 32. 11500. El Puerto de Santa María, Cádiz, indicando claramente los datos de contacto y remitiendo copia de su documento de identidad o enviando solicitud por email con asunto “Ejercicio de Derechos”, a protecciondatos@caballero.es, desde la misma cuenta de email que facilitó. Los Usuarios tienen derecho a retirar el consentimiento prestado y tienen derecho a reclamar ante la Autoridad de Control (Agencia Española de Protección de Datos www.aepd.es)." />

            <Text value="Se informa que el Delegado de Protección de Datos es: Grupo Datcon Sur S.L.U., C/ Dr. Duarte Acosta 7, 1.24, 11500, El Puerto de Santa María, Cádiz, ejercicioderechos@grupodatcon.com." />

            <H5 value="6. Campos de texto libre" type="display3" />

            <Text value="Los campos de texto libre que, a disposición del Usuario, puedan aparecer en los formularios del Sitio Web tienen como única y exclusiva finalidad el recabar información para mejorar la calidad de los Servicios." />

            <Text value="El Usuario no incluirá, en aquellos espacios que el Sitio Web pueda ofertar como &quot;campos de texto libre&quot;, ningún dato de carácter personal que pueda ser calificado dentro de aquellos datos para los que se exige un nivel de protección especial, entendiéndose como tales datos, a título enunciativo y no limitativo, los relativos a situación económico-financiera, perfiles psicológicos, ideología, religión, creencias, afiliación sindical, salud, origen racial y/o vida sexual." />

            <H5 value="7. Campos no obligatorios" type="display3" />

            <Text value="El Titular comunica al Usuario el carácter no obligatorio de la recogida de algunos datos, salvo en los campos que se indique lo contrario mediante un (*). No obstante, la no cumplimentación de dichos datos podrá impedir prestar todos aquellos Servicios vinculados a tales datos, liberándole de toda responsabilidad por la no prestación o prestación incompleta de estos Servicios." />

            <H5 value="8. Acceso y rectificación de los datos personales" type="display3" />

            <Text value="El Usuario se compromete a proporcionar información cierta en relación con sus datos personales, y mantener los datos facilitados al Titular siempre actualizados. El Usuario responderá, en cualquier caso, de la veracidad de los datos facilitados, reservándose el Titular el derecho de excluir de los servicios a todo Usuario que haya facilitado datos falsos, sin perjuicio de las demás acciones que procedan en Derecho. Los datos facilitados por el Usuario se presumirán correctos, por lo que, en caso de envío erróneo de sus datos por parte del Usuario, el Titular, declina cualquier responsabilidad en caso de la incorrecta ejecución o no ejecución del envío, así como el incorrecto cumplimiento de los trámites administrativos necesarios." />

            <H5 value="9. Comunicación de datos personales" type="display3" />

            <Text value="En relación a la gestión de los servicios, los datos de los Usuarios podrán ser tratados por empresas que presten al Titular diversos servicios, entre otros, de envío, mensajería, contabilidad, asesoría, mantenimiento informático, o cualquier otro que por su condición de Encargada del Tratamiento sea indispensable o inevitable que accedan o traten estos datos. Este tratamiento no será considerado en ningún caso una cesión de datos." />

            <Text value="El Titular no cederá sus datos a terceras personas en ningún caso." />

            <H5 value="10. Confidencialidad" type="display3" />

            <Text value="Además, también tendrá la condición de confidencial la información de cualquier tipo que las partes intercambien entre sí, aquella que éstas acuerden que tiene tal naturaleza, o la que simplemente verse sobre el contenido de dicha información. La visualización de datos a través de Internet, no supondrá el acceso directo a los mismos, salvo consentimiento expreso de su titular para cada ocasión." />

            <H4 value="Política de Cookies" type="display3" />

            <Text value="Este Sitio Web no genera actualmente Cookies propias o de terceros de las que sea obligatorio su información o consentimiento." />

          </TextWrapper>
          <AcceptButton
            onPress={() => onClick(false)}
          >
            <AcceptText
              value={'disclaimer.acceptButtonTitle'}
              translate
            />
          </AcceptButton>
        </Content>
      </Wrapper>
    </Modal>
  )
}

/* PropTypes */
DisclaimerModal.propTypes = {
  onClick: func.isRequired,
  visible: bool,
}

DisclaimerModal.defaultProps = {}

/* Local utility functions */

/* Styles */
const MainTitle = styled(Text)`
`

const Wrapper = styled(ScrollView)`
  flex:     1;
`

const Content = styled(View)`
  align-items: center;
  flex:        1;
  padding:     ${gaps.xl} 0;
`

const Logo = styled(View)`
  align-items: center;
`

const LogoImage = styled(Image)`
  height: 95px;
  width:  149px;
`

const TextWrapper = styled(View)`
  max-width:  800px;
  padding:    ${gaps.m} ${gaps.s};
  text-align: justify;
`

const H4 = styled(Text)`
  margin-top: ${gaps.l};
`

const H5 = styled(Text)`
  margin-top: ${gaps.l};
`

const AcceptButton = styled(TouchableOpacity)`
  border-color: ${disclaimerColors.main};
  border-width: 1px;
`

const AcceptText = styled(Text)`
  color:   ${disclaimerColors.main};
  padding: ${gaps.xs} ${gaps.m};
`

export default DisclaimerModal
