/* Global imports */
import React from 'react'
import { array } from 'prop-types'
import { FlatList, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { prop } from 'ramda'

/* Local imports */
import Text from 'Components/Text'
import { notificationsScreen } from 'Definitions/colors'

/* Component definition */
const NotificationsList = ({ notifications }) => {
  return (
    <FlatList
      data={notifications}
      keyExtractor={prop('_id')}
      renderItem={({ item }) => renderNotification(item)}
    />
  )
}

/* PropTypes */
NotificationsList.propTypes = {
  notifications: array,
}
NotificationsList.defaultProps = {}

/* Local utility functions */
const renderNotification = (notification) => {
  const date = new Date(notification.createdAt)

  return (
    <Wrapper onPress={() => null}>
      <NotificationWrapper>
        <DateWrapper>
          <Day
            type="body"
            value={('0' + date.getDay()).slice(-2)}
          />
          <Month
            type="body"
            value={getMonth(date).toUpperCase()}
          />
          <Year
            type="caption"
            value={date.getFullYear().toString()}
          />
        </DateWrapper>
        <ContentWrapper>
          <Title value={notification.title} type="body" />
          <Description
            value={notification.message}
            type="body" />
        </ContentWrapper>
      </NotificationWrapper>
    </Wrapper>
  )
}

const getMonth = (date) => {
  let month = []
  month[0] = 'Ene'
  month[1] = 'Feb'
  month[2] = 'Mar'
  month[3] = 'Abr'
  month[4] = 'May'
  month[5] = 'Jun'
  month[6] = 'Jul'
  month[7] = 'Ago'
  month[8] = 'Sep'
  month[9] = 'Oct'
  month[10] = 'Nov'
  month[11] = 'Dic'

  return month[date.getMonth()]
}

/* Styles */
const Wrapper = styled(TouchableOpacity)`
  padding: 0 10px;
`

const NotificationWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom-width: 1px;
  border-bottom-color: gray;
`

const DateWrapper = styled(View)`
  align-items: center;
`

const Day = styled(Text)`
  color: ${notificationsScreen.date};
  font-size: 24px;
  font-weight: bold;
`

const Month = styled(Text)`
  color: ${notificationsScreen.date};
  font-size: 15px;
  font-weight: bold;
`

const Year = styled(Text)`
  font-size: 14px;
`

const ContentWrapper = styled(View)`
  padding-left: 20px;
`

const Title = styled(Text)`

`

const Description = styled(Text)`
  color: ${notificationsScreen.description};
`

export default NotificationsList
