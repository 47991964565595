import { put } from 'redux-saga/effects'

import { successLogin, failureLogin } from 'Reducers/user'
import {
  requestUnread,
} from 'Reducers/notifications'

import api from 'Services/api'

export function * login({ payload }) {
  try {
    const { username: email, password } = payload
    const result = yield api('/auth/login').post({ email, password })

    if (result.ok) {
      const { data } = result

      yield put(successLogin(data.token))
      yield put(requestUnread())
    } else {
      yield put(failureLogin(result))
    }
  } catch (e) {
    yield put(failureLogin(e))
  }
}
