import { withRouter } from 'react-router-dom'
import Bottle from './Bottle'
import './animation.css'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export const TransitionHOCs = {
  CSSTransition,
  TransitionGroup,
}

export default withRouter(Bottle)
