import { put } from 'redux-saga/effects'

import { successList, failureList } from 'Reducers/brands'

import api from 'Services/api'

export function * getBrands({ payload = {} }) {
  const { category, callback = () => null } = payload

  try {
    const result = yield api('brands').getAll({ category })

    if (result.ok) {
      const { data } = result
      callback(data)
      yield put(successList(data))
    } else {
      yield put(failureList(result))
    }
  } catch (e) {
    yield put(failureList(e))
  }
}
