import { combineReducers } from 'redux'

export const reducers = {
  [require('./brands').slice]:             require('./brands').reducer,
  [require('./categories').slice]:         require('./categories').reducer,
  [require('./notifications').slice]:      require('./notifications').reducer,
  [require('./language').slice]:           require('./language').reducer,
  [require('./marketingResources').slice]: require('./marketingResources').reducer,
  [require('./products').slice]:           require('./products').reducer,
  [require('./sliders').slice]:            require('./sliders').reducer,
  [require('./user').slice]:               require('./user').reducer,
  [require('./sync').slice]:               require('./sync').reducer,
  [require('./disclaimer').slice]:         require('./disclaimer').reducer,
}

export default combineReducers(reducers)
