/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { View } from 'react-native'

/* Local imports */
import Text from 'Components/Text'

/* Component definition */
const ErrorMessage = () => {
  return (
    <Wrapper>
      <Text
        type="lead"
        value="somethingWentWrong"
        translate
      />
    </Wrapper>
  )
}

/* PropTypes */
ErrorMessage.propTypes = {}
ErrorMessage.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`

`

export default ErrorMessage
