/* Global imports */
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { func, object } from 'prop-types'
import styled from 'styled-components'
import { Col, Container, Row } from 'reactstrap'
import { withRouter } from 'react-router-dom' // TODO: add router
import { connect } from 'react-redux'
import { compose } from 'crocks'
import { propSatisfies } from 'crocks/predicates'
import { is, pathSatisfies } from 'ramda'
import { isMobileOnly } from 'react-device-detect'

/* Local imports */
import IconButton from 'Components/IconButton'
import { icons } from 'Assets/images'
import ProductDetail from 'Components/ProductDetail'
import ProductDetailMobile from 'Components/ProductDetail/mobile.js'
import Image from 'Components/Image'
import { gaps } from 'Definitions/measures'
import { productShape } from 'Definitions/types'
import ResourceMenu from 'Components/ProductDetail/ResourceMenu'
import { getCurrent, requestDetail as requestProduct } from 'Reducers/products'

/* Component definition */
const ProductScreen = ({ getProduct, product: connectedProduct, history, location, match }) => {
  const [ show, setShow ] = useState(false)
  const { productId } = match.params

  const { product: locationProduct } = location

  const product = locationProduct || connectedProduct

  useEffect(() => {
    if (!locationProduct) {
      getProduct(productId)
    }
  }, [])

  if (isMobileOnly) {
    return (
      <SContainer fluid>
        <Row>
          <LeftHeader xs="3">
            <IconButton
              onClick={() => history.goBack()}
              text="back"
              icon={icons.back}
              textPosition="right"
            />
          </LeftHeader>
          <CenterHeader xs="6">
            <View>
              {
                hasBrandImage(product) ? <SImage alt="text" source={product.brand.logoUrl} /> : null
              }
            </View>
          </CenterHeader>
          <RightHeader xs="3">
            { hasMR(product) ? <IconButton text="resources" onClick={() => setShow(!show)} icon={icons.resources} /> : null }
          </RightHeader>
          {
            hasMR(product) ? <ResourceMenu
              onClick={() => setShow(!show)}
              visible={show}
              resources={product.marketingResources} /> : null
          }
        </Row>

        <>
          { product ? <ProductDetailMobile product={product} /> : null }
        </>
      </SContainer>

    )
  }

  return (
    <SContainer fluid>
      <Row>
        <LeftHeader md="3">
          <IconButton
            onClick={() => history.goBack()}
            text="back"
            icon={icons.back}
            textPosition="right"
          />
        </LeftHeader>
        <CenterHeader md="6">
          <View>
            {
              hasBrandImage(product) ? <SImage alt="text" source={product.brand.logoUrl} /> : null
            }
          </View>
        </CenterHeader>
        <RightHeader md="3">
          { hasMR(product) ? <IconButton text="resources" onClick={() => setShow(!show)} icon={icons.resources} /> : null }
        </RightHeader>
        {
          hasMR(product) ? <ResourceMenu
            onClick={() => setShow(!show)}
            visible={show}
            resources={product.marketingResources} /> : null
        }
      </Row>

      <ProductCol>
        { product ? <ProductDetail product={product} /> : null }
      </ProductCol>
    </SContainer>
  )
}

/* Local Styled Components */
const SContainer = styled(Container)`
  display:   flex;
  flex-flow: column;
  height:    100%;
  position:  relative;
`

const ProductCol = styled(Col)`
  padding-top: ${gaps.m};
  position: absolute !important;
  top: 70px;
  bottom: 0;
`

const LeftHeader = styled(Col)`
  display:         flex;
`

const CenterHeader = styled(Col)`
  align-items:     center;
  display:         flex;
  justify-content: center;
`

const RightHeader = styled(Col)`
  display:         flex;
  justify-content: flex-end;
`

const SImage = styled(Image)`
  max-height: 100px;
  max-width: 100px;
`

/* PropTypes */
ProductScreen.propTypes = {
  getProduct: func.isRequired,
  history:    object.isRequired,
  location:   object,
  match:      object,
  product:    productShape,
}

ProductScreen.defaultProps = {}

/* Local utility functions */

const hasMR = propSatisfies('marketingResources', x => x.length > 0)

const hasBrandImage = pathSatisfies(is(String), ['brand', 'logoUrl'])

const mapStateToProps = (state) => ({
  product: getCurrent(state),
})

const mapDispatchToProps = (dispatch) => ({
  getProduct: (id) => dispatch(requestProduct(id)),
})

const enhanceComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)

/* Local Styled Components */

export default enhanceComponent(ProductScreen)
