/* Global imports */
import React, { useEffect } from 'react'
import {
  array,
  bool,
  func,
  object,
} from 'prop-types'
import { ActivityIndicator, Platform, View } from 'react-native'
import { connect } from 'react-redux'
import styled from 'styled-components'

/* Local imports */
import {
  requestMarkRead,
  getList as getNotificationsList,
  getError as getNotificationsError,
  getFetching as getNotificationsFetching,
  requestList as requestNotificationsList,
} from 'Reducers/notifications'
import ErrorMessage from 'Components/ErrorMessage'
import Text from 'Components/Text'
import NotificationsList from 'Components/NotificationsList'
import { catalogContent } from 'Definitions/colors'

/* Component definition */
const NotificationsScreen = ({
  error,
  fetching,
  getNotifications,
  markReadNotifications,
  notifications,
}) => {
  useEffect(() => {
    getNotifications()
    markReadNotifications()
  }, [])

  if (fetching) {
    return (
      <MessageWrapper>
        <ActivityIndicator size="large" color={catalogContent.spinner} />
      </MessageWrapper>
    )
  }

  if (error) {
    return (
      <MessageWrapper>
        <ErrorMessage />
      </MessageWrapper>
    )
  }

  if (notifications.length === 0) {
    return (
      <MessageWrapper>
        <Text value="notifications.noNotifications" translate />
      </MessageWrapper>
    )
  }

  return (
    <Wrapper>
      <NotificationsList notifications={notifications} />
    </Wrapper>
  )
}

/* PropTypes */
NotificationsScreen.propTypes = {
  error:                 bool,
  fetching:              bool,
  getNotifications:      func.isRequired,
  markReadNotifications: func.isRequired,
  navigation:            object,
  notifications:         array,
}
NotificationsScreen.defaultProps = {}

/* Local utility functions */

/* Styles */
const Wrapper = styled(View)`
  ${Platform.OS === 'web' ? 'height: 100%; position: relative' : null};
  flex: 1;
`

const MessageWrapper = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
`

const mapStateToProps = (state) => ({
  error:         getNotificationsError(state),
  fetching:      getNotificationsFetching(state),
  notifications: getNotificationsList(state),
})

const mapDispatchToProps = (dispatch) => ({
  getNotifications:      (params) => dispatch(requestNotificationsList(params)),
  markReadNotifications: () => dispatch(requestMarkRead()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsScreen)
