import autodux from 'autodux'

export const {
  reducer,
  initial,
  slice,
  actions: {
    failure,
    request,
    success,
  },
  selectors: {
    getError,
    getSlides,
    getFetching,
  },
} = autodux({
  // No need to implement switching logic -- it's
  // done for you.
  actions: {
    failure: (state) => ({ ...state, fetching: false, error: true }),
    request: (state) => ({ ...state, fetching: true, error: false }),
    success: (state, slides) => ({ ...state, fetching: false, error: false, slides }),
  },

  // The initial value of your reducer state
  initial: {
    error:    false,
    fetching: false,
    slides:   [],
  },

  // No need to select the state slice -- it's done for you.
  selectors: {
  },

  // the slice of state your reducer controls
  slice: 'sliders',
})
